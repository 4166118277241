@import '../../../Assets/Styles/Imports.scss';

.warningPopUp {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), rgba(31, 31, 31, 0.5);
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(89.7033px);
}

.main{
    position: relative;
    max-width: 720px;
    padding: 20px;
    border-radius: unset;
}

.main button{
    padding: 18px 32px;
    font-size: 18px;
    font-weight: bold;
}

.checkContainer{
    position: absolute;
    top: -40px;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #EC900E;
    box-shadow: 0px 0px 18px grey;
}

.checkContainer > img{
    margin: auto;
    width: 40px;
    height: 40px;
}

.actionBar{
    text-align: right;
}
.actionBar > img{
    width: 27px;
    @include mobile-size{
        width: 26px;
    }
}