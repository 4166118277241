@import '../../../Assets/Styles/Imports.scss';


.main{
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
    letter-spacing: 0.833333px;
    cursor: pointer;
}

.moreInfo {
    color: white;
    
    text-decoration: none;

    @include mobile-size {
        font-size: 18px;
        line-height: 30px;
        letter-spacing: 0.75px;
        display: flex;
    }
}

.moreInfoImg {
    display: inline-block;
    max-height: 100%;
    margin-right: 10px;
    pointer-events: none;
    user-select: none;
}
