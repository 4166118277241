@import "../../../Assets/Styles/Imports.scss";

.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @include mobile-size {
    padding: 0;
  }
}

.main > h4 {
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  padding-top: 16px;
}

.main > p {
  text-align: center;
  color: $PrimaryTextColor;
  font-size: 16px;
  padding-top: 20px;
  margin-bottom: 36px;
}

.splitView {
  column-gap: 50px;

  @include mobile-size {
    display: block;
    padding-right: 18px;
    padding-left: 18px;
  }
}

.titleSection {
  max-width: 650px;

  & h1 {
    @include mobile-size {
      font-size: 36px;
      line-height: 46px;
      letter-spacing: 0.72px;
    }
  }

  & h3 {
    @include mobile-size {
      font-size: 22px;
      letter-spacing: 1.1px;
    }
  }
}

.titleSection * {
  text-align: left;
}

.panel {
  padding: 40px;
}

.panel p {
  font-weight: 500;
  font-size: 21px;
  line-height: 34px;
  letter-spacing: 0.875px;
}

.contentSection {
  padding-top: 50px;
  max-width: 630px;
}

.contentSection > *:first-child {
  margin-bottom: 40px;

  @include mobile-size{
    margin-bottom: 20px;
  }
}

.contentSection > *:last-child {
  @include mobile-size{
    margin-bottom: 60px;
  }
}

.radioContainer {
  display: flex;
  column-gap: 32px;
}

.button {
  margin-top: auto;
  margin-bottom: 20px;
}

.errorMessage {
  margin-left: 20px;
  margin-top: 84px;
  max-width: unset;
}
