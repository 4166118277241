@import "../../../Assets/Styles/Imports.scss";

.main {
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    padding-left: 30px;
    margin-left: 10px;
}

.message {
    display: flex;
    align-items: flex-start;
    margin-bottom: 44px;
    position: relative;
}

.message::before {
    content: "";
    width: 21px;
    height: 20px;
    position: absolute;
    top: 3px;
    left: -41px;
    background-image: url(../../../Assets/Images/point3d.svg);
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    backdrop-filter: blur(13.5914px);
}

.newMessage {
    background: $ActionColor;
    border-radius: 20px;
}

.date {
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 27px;
    padding-left: 8px;
    padding-right: 8px;
}

.titleWrapper {
    display: flex;
    flex-direction: column;
    margin-left: 25px;
}

.title {
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 25px;
}

.downloadLink {
    font-size: 12px;
    line-height: 22px;
    color: #948E8D;
    display: flex;
    align-items: center;
    margin-top: 2px;
    cursor: pointer;
}

.downloadLink::before {
    content: "";
    width: 16px;
    height: 16px;
    margin-right: 10px;
    background-image: url(../../../Assets/Images/Dashboard/Documents/download_icon.svg);
    background-size: contain;
}

.link {
    color: $SecondaryTextColorGray;
    margin-left: 96px;

    &:hover {
      color: $SecondaryTextColorGray;
    }

    @include mobile-size{
        margin-left: 0;
    }
}
