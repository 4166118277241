@import "../../../Assets/Styles/Imports.scss";

.main {
    cursor: pointer;
    width: 346px;
    height: 240px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), rgba(15, 15, 15, 0.3);
    border: 1px solid #585656;
    backdrop-filter: blur(13.5914px);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 30px;
    position: relative;
    text-decoration: none;

    @include mobile-size{
        width: 100%;
    }
}

.icon {
    width: 80px;
    height: 80px;
    margin-bottom: 18px;
    position: absolute;
}

.title {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 1px;
    color: $SecondaryTextColorGray;
    margin-top: 100px;
}

.description {
    font-weight: 500;
    font-size: 21px;
    line-height: 34px;
    letter-spacing: 0.875px;
    text-decoration: none;
    color: $WhiteColor;

    &:hover {
        color: $WhiteColor;
    }

    @include mobile-size{
        font-size: 19px;
        line-height: 31px;
        letter-spacing: 0.791667px;
    }
}

.action {
    position: absolute;
    bottom: 11px;
    right: 20px;
    width: 20px;
    height: 20px;
}
