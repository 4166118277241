@import "../../../Assets/Styles/Imports.scss";

.splitView {
    column-gap: 60px;

    @include mobile-size {
        display: block;
    }
}

.titleSection {
    max-width: 700px;
    margin-right: auto;
    padding-bottom: 150px;
}

.title {
    font-weight: 500;
    font-size: 70px;
    line-height: 80px;
    letter-spacing: 1.4px;
    text-align: left;

    @include mobile-size {
        margin-bottom: 20px;
        line-height: 42px;
        font-size: 30px;
    }
}

.subtitle {
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 25px;
    line-height: 38px;
    /* or 152% */
    
    letter-spacing: 1.25px;
    color: $PrimaryTextColor;

    @include mobile-size {
        font-size: 20px;
    }
}

@include dark-mode {
    .title,
    .subtitle {
        color: #fff;
    }
}
