@import "../../../Assets/Styles/Imports.scss";

.label {
    font-size: 20px;
    display: flex;
    margin-bottom: 5px;
    line-height: 40px;
    cursor: pointer;

    @include mobile-size {
        font-size: 18px;
        line-height: 30px;
    }
}

.label > input {
    appearance: none;
    -webkit-appearance: none;
    position: relative;
    background: transparent;
    width: 40px;
    height: 40px;
    vertical-align: middle;
    margin-right: 10px;
    border: none;
    cursor: pointer;

    @include mobile-size {
        width: 30px;
        height: 30px;
    }
}

.label > input::before {
    content: "";
    background: transparent;
    border: 1px solid $BorderGrayColor;
    border-radius: 100px;
    width: 40px;
    height: 40px;
    position: absolute;

    @include mobile-size {
        width: 30px;
        height: 30px;
    }
}

.label > input::after {
    content: "";
    background: transparent;
    border: unset;
}

.label > input:checked {
    background: transparent;
    border: unset;
}

.label > input:checked::after {
    position: absolute;
    top: 10%;
    bottom: 10%;
    left: 10%;
    right: 10%;
    background: $BorderGrayColor;
    border-radius: 100px;
    border: none;
}

@include dark-mode {
    .label > input::before {
        border: 1px solid #fff;
    }

    .label > input::after {
        background-color: white;
    }
}
