@import '../../../../Assets/Styles/Imports.scss';

.splitView {
    column-gap: 60px;
    
    @include mobile-size {
        display: block;
    }
}

.title {
    text-align: left;
}

.titleSection {
    max-width: 700px;
}

.contentSection {
    width: 100%;
    max-width: 770px;
    min-width: 500px;
}

.link:hover {
    color: $ActionColor;
}

label {
    margin-bottom: 50px;
    display: block;
}

.button {
    width: 275px;
}