@import '../../../Assets/Styles/Imports.scss';

.main{
    cursor: pointer;
}

.dropdownMenu {
    width: 277px;
    padding: 24px 17px 24px 15px;
    background: linear-gradient(0deg, rgba(15, 15, 15, 0.6), rgba(15, 15, 15, 0.6)), rgba(255, 255, 255, 0.05);
    border: 1px solid #585656;
    box-sizing: border-box;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(13.5914px);
    margin-left: -17px !important;
    margin-right: -17px !important;
}

.dropdownItem {
  padding:  9px 10px 9px 15px;
  border-radius: 2px;
}

.dropdownItem:hover {
    background: #00A76D;
}

.icon {
    width: 27px;
}

@include dark-mode{
    .dropdownItem{
        color: $WhiteColor;
    }
}
