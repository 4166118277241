@import './Device.scss';

@mixin screen-padding{
    padding: 40px 55px;

    @include mobile-size{
        padding: 20px;
    }
}

@mixin view-full-width {
    width: 100vw;
    max-width: 100vw;
}
