@import '../../../Assets/Styles/Imports.scss';

.main{
    display: flex;
    column-gap: 10px;
    margin: 0 auto;
    justify-content: center;

    @include mobile-size{
        display: block;
        padding: 20px;
    }
}