@import "../../../Assets/Styles/Imports";

.list {
  padding-left: 25px;
  margin: 0;
}

.listItem {
  padding-left: 15px;
  margin-bottom: 12px;

  font-weight: 400;
  font-size: 21px;
  line-height: 34px;
  letter-spacing: 0.875px;

  &::marker {
    font-weight: 700;
    font-size: 21px;
    color: $ActionColor;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.description {
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 21px;
  line-height: 34px;
  letter-spacing: 0.875px;
}

.datepickerContainer {
  margin-top: 40px;
}

.datepicker {
  border: 1px solid $WhiteColor;
  max-width: 355px;
  padding-left: 14px;
}

.datepickerIcon {
  right: 14px;
}

.textarea {
  background-color: transparent;
  width: 100%;
  min-width: 770px;
  min-height: 226px;
  color: white;

  @include mobile-size {
    min-width: unset;
  }
}

.textareaPlaceholder {
  margin-bottom: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 34px;
  letter-spacing: 0.875px;
  color: $SecondaryTextColorGray;
}

.textareaButton {
  margin-top: 60px;
  width: 188px;
}