@import "../../../Assets/Styles/Imports";

.downloadButton {
  display: flex;
  align-items: center;
  max-width: 400px;
  min-height: 80px;
  text-align: left;
  column-gap: 10px;
  padding: 12px 14px;
  margin: 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(13.5914px);
  border-radius: 10px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), rgba(15, 15, 15, 0.4)!important;

  @include mobile-size {
    max-width: 400px;
    margin: 55px auto 0;
    align-items: center;
  }
}

.downloadIcon {
  pointer-events: none;
  user-select: none;
}