@import "../../../Assets/Styles/Imports.scss";

.salutationWrapper .answerContainer{
    margin-top: 50px;
}

.titleAnswer{
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 38px;
    letter-spacing: 1.25px;
    color: $WhiteColor;
}

.labelOptional {
    font-size: 20px;
    margin-bottom: 2px;
  
    @include mobile-size {
      font-size: 18px;
    }
}
  






