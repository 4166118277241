@import "../../../Assets/Styles/Imports.scss";

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 38px;
  letter-spacing: 1.25px;
  margin-bottom: 40px;

  @include mobile-size{
    font-size: 22px;
    line-height: 32px;
    letter-spacing: 1.1px;
    margin-bottom: 30px;
  }
}

.productList {
  display: flex;
  column-gap: 53px;

  @include mobile-size {
    flex-direction: column;
  }
}