@import "../../../Assets/Styles/Imports.scss";

.panel {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 294px;
    width: 100%;
    max-width: 769px;
    border-radius: 20px;

    @include mobile-size{
        padding: 20px;
    }
}

.panel > div > img{
    cursor: pointer;
    @include mobile-size{
        display: none;
    }
}

.valueContainer{
    display: flex;
    align-items: flex-end;

    @include mobile-size{
        margin-bottom: 30px;
    }
}

.valueDiv {
    border-radius: 10px;
    margin: 0 40px;
    position: relative;
    width: 342px;

    @include mobile-size{
        width: 100%;
        margin: unset;
    }
}


.valueDiv > h5 {
    margin: auto;
    padding: 0 32px;
}

.valueDiv > span {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    position: absolute;
    top: 16px;
    right: 0;
}

.input {
    width: 100%;
    height: 60px;
    text-align: center;
    font-size: 44px;
    line-height: 54px;
    letter-spacing: 1.1px;
    padding-right: 50px;
    padding-left: 50px;
    background: none;
    border-bottom: 1px solid $WhiteColor;
    border-right: none;
    border-top: none;
    border-left: none;
    color: white;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.input[type=number] {
  -moz-appearance: textfield;
}

@include dark-mode {
    .panel {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), rgba(15, 15, 15, 0.4);
        border: 1px solid #585656;
        backdrop-filter: blur(13.5914px);
    }
}
