@import '../../../Assets/Styles/Imports.scss';

.main{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.content {
  padding-bottom: 0;
}

.textBlock {
  display: flex;
  justify-content: space-around;
  margin-bottom: 60px;

  @include mobile-size{
    display: block;
    padding: 20px;
  }
}

.textBlock h1 {
  letter-spacing: 1.4px;
  display: inline-block;
  text-align: left;
  max-width: 700px;

  @include mobile-size{
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    letter-spacing: 0.72px;
  }
}

.textBlock h3 {
  display: block;
  width: 600px;
  padding-top: 20px;

  @include mobile-size{
    width: auto;

    font-weight: 400;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0.6px;
  }
}
