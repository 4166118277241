@import "../../../../Assets/Styles/Imports.scss";

.main {
  display: flex;
  column-gap: 200px;
  height: 100%;

  @include mobile-size{
    display: block;
  }
}

.main > button {
  margin-top: auto;
  margin-bottom: 30px;
}

.titleSection{
  flex-shrink: 0;
  max-width: 700px;
}

.titleSection h1{
  word-break: break-all;
  text-align: left;
}

.secondaryText {
  color: $SecondaryTextColor;
  margin-bottom: 20px;
  max-width: 530px;
}

.selector {
  width: 100%;
  max-width: 635px;
  padding-top: 160px;

  @include mobile-size{
    padding-top: 20px;
  }
}

.choiceText {
  font-weight: 500;
  font-size: 25px;
  letter-spacing: 1.25px;

  @include mobile-size {
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.75px;
  }
}

.description {
  display: block;
  margin-top: 20px;
  font-weight: 400;
  font-size: 21px;
  line-height: 34px;
  letter-spacing: 0.875px;
}

@include dark-mode {
  .secondaryText {
    color: #fff;
  }
}
