@import "../../../Assets/Styles/Imports";

.listContainer {
  max-width: 510px;
}

.listHeader {
  font-weight: 500;
  margin-bottom: 20px;
}

.list {
  padding-left: 25px;
  margin: 0;
}

.listItem {
  padding-left: 15px;
  margin-bottom: 12px;

  font-weight: 400;
  font-size: 21px;
  line-height: 34px;
  letter-spacing: 0.875px;

  &::marker {
    font-weight: 700;
    font-size: 21px;
    color: $ActionColor;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.description {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 21px;
  line-height: 34px;
  letter-spacing: 0.875px;
}