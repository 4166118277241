$PrimaryTextColor: #283847;
$SecondaryTextColor: #90A3B0;

$BorderGrayColor: #405858;
$BorderLightGrayColor: #BCCED9;

$InputShadowColor: rgba(144, 163, 176, 0.5);

$TileBlueColor: #E0E7F6;
$TileLightBlueColor: #F1F4FA;

$GrayLine: #979797;
$DarkGrayLine: #585656;

$WhiteColor: #FFF;
$BlackColor: #000;

$ActionColor: #00A76D;
$ErrorColor: #F9635C;
$SecondaryTextColorGray: #D5D2CD;
