@import "../../../Assets/Styles/Imports.scss";

.product {
  width: 360px;
  height: 500px;
  color: $SecondaryTextColor;
  margin-right: 62px;
  float: left;
  padding: 100px 0 0;

  &:hover {
    background: #e0e7f6;
  }

  @include mobile-size{
    height: auto;
    width: 100%;
  }
}

.product *{
  user-select: none;
}

.product > div:first-child {
  top: 27px;
  left: 25px;
}

.title {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  padding: 0 25px;
  margin-bottom: 20px;


  @include mobile-size{
    font-size: 20px;
  }
}

.description {
  font-size: 20px;
  padding: 0 25px;
  margin-bottom: 85px;


  @include mobile-size{
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 60px;
  }
}

.cta {
  font-size: 18px;
  color: $SecondaryTextColor;

  &:hover {
    color: $SecondaryTextColor;
  }
}

@include dark-mode {

  .title{
    color: #FFF;
  }

  .description{
    color: #D5D2CD;
  }
}
