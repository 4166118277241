@import "../../../Assets/Styles/Imports.scss";

.link {
  display: block;
  text-decoration: none;
  max-width: 635px;

  &:hover {
    .description {
      color: $ActionColor;
    }
  }
}

.surveyAnswer {
  cursor: pointer;
}

.title {
  font-weight: 500;

  @include mobile-size {
    font-size: 18px;
    line-height: 30px;

    letter-spacing: 0.75px;
  }
}

.description {
  font-weight: normal;
  font-size: 21px;
  line-height: 34px;
  letter-spacing: 0.875px;

  @include mobile-size {
    font-size: 18px;
    line-height: 30px;
    /* or 167% */

    letter-spacing: 0.75px;
  }
}

@include dark-mode {
  .title,
  .description {
    color: white;
  }
}
