@import "../../../Assets/Styles/Imports.scss";

.main {
    padding: 116px 50px 0 50px;
}

.title {
    margin-bottom: 40px;
}

.subtitle {
    font-weight: 500;
    font-size: 25px;
    line-height: 38px;
    letter-spacing: 1.25px;
    margin-bottom: 34px;
}

.section {
    margin-bottom: 100px;
}

.deposits {
    display: flex;
    column-gap: 46px;
    row-gap: 46px;
    flex-wrap: wrap;
}

.containerStrategy {
    margin-bottom: 100px;
    display: flex;
    width: 100%;
    padding-bottom: 150px;

    background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), rgba(15, 15, 15, 0.3);
    border: 1px solid #585656;
    backdrop-filter: blur(13.5914px);
    border-radius: 10px;
}

.sectionStrategy {
    margin-top: 38px;
    margin-left: 46px;
    margin-right: 46px;
}

.sectionSubMenu {
    flex: 1;
}

.productsList {
    display: flex;
    column-gap: 53px;
}

.submain {
    position: relative;
    min-width: 478px;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 187px;
}


.score {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 1px;
    color: $SecondaryTextColorGray;
}

.type {
    font-size: 19px;
    line-height: 32px;
    letter-spacing: 0.791667px;
    color: $WhiteColor;
}

.amountWrapper {
    margin-top: 26px;
    margin-bottom: 22px;
}

.amount {
    font-weight: 500;
    font-size: 44px;
    line-height: 54px;
    letter-spacing: 1.1px;
    color: $WhiteColor;
}

.currency {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 1px;
    color: $WhiteColor;
    padding-left: 11px;
}

.difference {
    color: $ActionColor;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.5px;
    display: flex;
    padding: 0 15px;
    width: fit-content;
    margin-bottom: 64px;

    background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), rgba(15, 15, 15, 0.4);
    border: 1px solid #585656;
    backdrop-filter: blur(13.5914px);
    border-radius: 23px;
}

.difference img {
    padding-right: 8px;
}

.differenceValue {
    padding-left: 6px;
    padding-right: 6px;
}

.action {
    position: absolute;
    bottom: 30px;
    right: 30px;
    width: 20px;
    height: 20px;
}

.titleStrategy{
    font-weight: 500;
    font-size: 44px;
    line-height: 54px;
    letter-spacing: 1.1px;
    margin-bottom: 30px;
}

.text{
    font-size: 21px;
    line-height: 34px;
    margin-bottom: 0;
}

.icon{
    width: 60px;
    height: 60px;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(69.8987px);
    border-radius: 30px;
    margin-right: 20px;

    &::after{
        background-size: contain;
        background-repeat: no-repeat;
        width: 25px;
        height: 25px;
        content: "";
        position: absolute;
        right: 17.5px;
        top: 17.5px;
    }

    &:first-child{
        margin-bottom: 20px;
    }
}

.iconContainer{
    display: flex;
    align-items: center;
    position: relative;
}

.iconDarts::after{
    background-image: url(../../../Assets/Images/darts.svg);
}
.iconPoint::after{
    background-image: url(../../../Assets/Images/three-dots.svg);
}

.iconSection{
    position: absolute;
    right: 0;
    top: 70px;
}

.productCards{
    & div{
        flex-direction: row-reverse;
    }
}