@import '../../../Assets/Styles/Imports.scss';

.portfolios{
    display: flex;
    width: 100%;
    column-gap: 60px;
    margin-top: 65px;
    flex-wrap: wrap;
    row-gap: 30px;
    justify-content: center;

    @include mobile-size{
        margin-bottom: 30px;
    }
}

.portfolios > *{
    width: 100%;
}