@import "../../../Assets/Styles/Imports.scss";

.radio > input:checked {
    background-color: $ActionColor;
    background-image: url(../../../Assets/Images/Checkmark.svg);
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50%;
    border: none;
    border-radius: 50%;
}

.radio > input:checked::before {
   border: none !important;
}

.radio > input:checked::after {
    background-color: transparent !important;
 }
 
