@mixin Field {
    padding: 12px 12px 12px 0;
    background-color: transparent;
    color: #3D5463;
    width: 100%;
    height: 60px;
    border: none;
    border-bottom: 1px solid black;
    text-align: left;
    align-items: center;
    border-radius: 3px;
    outline: unset !important;
    font-size: 20px;
}

@mixin Field-mobile {
    font-size: 18px;
}

@mixin Field-active {
    @include Field();
}

@mixin Field-dark {
    color: #FFF;
    border-bottom: 1px solid #FFF;
}
