@import "../../../../../src/Assets/Styles/Imports.scss";

.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.content {
  padding-bottom: 0;
}

.textBlock {
  display: flex;
  justify-content: space-around;
  margin-bottom: 60px;
  
  @include mobile-size {
    display: block;
    margin-right: 20px;
    margin-left: 20px;
  }
}

.textBlock h1 {
  letter-spacing: 1.4px;
  display: inline-block;
	text-align: left;
  max-width: 700px;

  @include mobile-size {
    font-size: 36px;
    line-height: 46px;
    letter-spacing: 0.72px;
  }
}

.textBlock h3 {
  display: block;
  width: 100%;
  max-width: 775px;
  padding-top: 20px;
}
