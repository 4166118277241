@import '../../../Assets/Styles/Imports.scss';

.image{
    max-width: 100%;
    max-height: 100%;
    pointer-events: none;
    user-select: none;
}

// @include dark-mode{
//     .image{
//         // filter: invert(100%) sepia(0%) saturate(1100%) hue-rotate(85deg) brightness(107%) contrast(93%);
//     }
// }