@import '../../../Assets/Styles/Imports.scss';

.main {
    max-width: 743px;
    margin: 0 auto;
}

.description {
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 38px;
    letter-spacing: 1.25px;
    margin-bottom: 80px;
}

.passReqList {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 15px;
    column-gap: 30px;
    max-width: 500px;
    list-style-type: none;
    letter-spacing: 0.5px;
    padding: 0;
}

.passReqItem {
    position: relative;
    min-width: 230px;
    padding-left: 37px;

    &:before {
        position: absolute;
        content: "";
        width: 20px;
        height: 20px;
        left: 0;
        background-image: url("../../../Assets/Images/Checkmark-little.svg");
        background-size: 20px 20px;
    }
}

.passwordLink:hover {
    cursor: pointer;
}

.notMatched {
    &:before {
        background-image: none;
    }
}

.form > label{
    font-size: 18px;
    width: 100%;
}

.from > label::before{
    content: ' ';
}

.form > *{
    margin-bottom: 50px;
}

.form hr{
    margin: 80px 0;

    @include mobile-size{
        margin: 60px 0;
    }
}

.button {
    width: 275px;
    background: none !important;
    color: $ActionColor;
    border: 1px solid $ActionColor;
    border-radius: 16px;
}

.salutationWrapper {
    margin-bottom: 60px;

    @include mobile-size {
        margin-bottom: 22px;
    }
}

.radioWrapper {
    display: flex;

    @include mobile-size{
        display: unset;
    }
}

.passwordInput {
    max-width: 460px;

    @include mobile-size{
        max-width: unset;
    }
}

.radio{
    margin-right: 40px;

    @include mobile-size{
        display: block;
        margin-bottom: 25px;
    }
}

.postalSection{
    display: flex;

    @include mobile-size{
        display: block;
    }
}

.postalSection > label:first-child{
    min-width: 120px;
    margin-right: 10px;

    @include mobile-size{
        min-width: unset;
        width: 100%;
        margin-right: unset;
        margin-bottom: 50px;
    }
}

.postalSection > label:last-child{
    width: 100%;
}

.postalSection > label {
    margin-bottom: 0;
}

.residence > div {
    margin-top: 10px;
}

.birthdate{
    margin-bottom: 0;
}

.birthdate > div{
    margin-top: 10px;
}

.birthdate > div  input{
    width: 100%;

    @include mobile-size{
        max-width: unset;
    }
}

@include dark-mode {
    .passReqItem {
        &:before {
            filter: invert(100%);
        }
    }
}
