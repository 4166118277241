
@import '../../../Assets/Styles/Imports.scss';

.main {
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 38px;
    letter-spacing: 1.25px;

    @include mobile-size {
        font-size: 18px;
        line-height: 30px;
        letter-spacing: 0.75px;
    }
}
