@import '../../../Assets/Styles/Imports.scss';

.radio {
    appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 70px;
    height: 35px;
    transition: 0.5s;

    @include mobile-size{
        width: 44px;
        height: 22px;
    }
}

.radio::before{
    content: "";
    width: 100%;
    height: 17%;
    background: #948e8d;
    border-radius: 15px;

}

.radio::after {
    content: "";
    cursor: pointer;
    position: absolute;
    width: 50%;
    padding-top: 50%;
    top: 0;
    left: 0;
    margin: auto;
    background: #948E8D;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    transition: 0.5s;
}

.radio:checked::before {
    background: $ActionColor;
}

.radio:checked::after {
    background: $ActionColor;
    left: 50%;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
}
