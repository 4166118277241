@import '../../../../Assets/Styles/Imports.scss';

.text {
  font-size: 25px;
  @include mobile-size {
    font-size: 25px;
  }
}

@include dark-mode {
  .main {
    color: #FFF;
  }
}
