@import '../../../../Assets/Styles/Imports.scss';

.main{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    
}

.main > h4 {
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    padding-top: 20px;
    margin-bottom: 50px;
}

.button{
    margin-top: auto;
    margin-bottom: 50px;
}

.splitView{
    column-gap: 40px;
}

.titleSection{
    max-width: 700px;
}
.titleSection *{
    text-align: left;
}

.answerSection{
    padding-top: 100px;

    @include mobile-size{
        padding-top: 20px;
    }
}

