@import '../../../Assets/Styles/Imports.scss';

.header {
  display: flex;
  justify-content: space-between;
  padding: 120px 70px 65px 180px;
  background: #1e1e1e;
}

.account {
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 38px;
  letter-spacing: 1.25px;
  color: #948E8D;
  margin: 0;
}

.portfolioName {
  font-weight: 400;
  font-size: 25px;
  line-height: 38px;
  letter-spacing: 1.25px;
  margin-bottom: 50px;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 70px;
  line-height: 80px;
  letter-spacing: 1.4px;
}

.subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 0.75px;
  color: $WhiteColor;
  margin-bottom: 40px;
}

.paragraph {
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 34px;
  letter-spacing: 0.875px;
  color: $WhiteColor;
  padding-bottom: 80px;
}

.strategyButtonContainer {
  align-self: flex-end;
}

.editStrategyButton {
  display: flex;
  align-items: center;
  column-gap: 20px;
  border: 0;
  margin: 20px 0;
  padding: 0;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.5px;

  &:hover {
    color: $ActionColor;
  }

}

.buttonIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;

  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 2px 17px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(69.8987px);
  border-radius: 30px;
}

.container {
  margin: 80px auto 0;
  max-width: 1005px;
}

.largeButton {
  margin: 120px 0 120px auto;
}

.confirmButton {
  max-width: 260px;
}

.contentPortfolioDetailsMenu {
  position: absolute;
  z-index: 5;
  background: #000;
  width: 277px;
  top: 0;
  right: 0;
  padding: 25px;

  @include mobile-size {
    position: relative;
    width: 100%;
  }

  img {
    float: right;
  }

}

.contentPortfolioDetailsMenuLink {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: $WhiteColor;
  padding: 5px 15px;

  &:hover {
    background: $ActionColor;
    border-radius: 2px;
    cursor: pointer;
  }

}

.whiteIcon {
  filter: brightness(0) invert(1);
}

@include dark-mode {
  .editStrategyButton {
    background: transparent;
  }
}

.grayBG {
  background: #1e1e1e;
}

.showSuccessContainer {
  text-align: center;
    img {
      display: inline-block;
      margin-bottom: 60px;
    }
    h3 {
      display: inline-block;
      width: 100%;  
      text-align: center;
    }
}