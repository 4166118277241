@import '../../../Assets/Styles/Imports.scss';

.flex {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

hr {
    margin: 80px 0;

    @include mobile-size{
        margin: 60px 0;
    }
}

.title{
    text-align: left;
}

.subtitle{
    font-weight: 400;
    font-size: 19px;
    line-height: 31px;
    letter-spacing: 0.8px;
    margin-bottom: 40px;
}

.tdNone{
  text-decoration: none;
}