@import '../../../Assets/Styles/Imports.scss';

.intro {
    margin: 182px 168px 182px 100px;
    display: flex;

    @include mobile-size{
        display: block;
        margin: 15px;
    }
}

.title {
    font-weight: 500;
    font-size: 70px;
    line-height: 80px;
    letter-spacing: 1.4px;
    flex: 1;

    @include mobile-size{
        font-size: 36px;
        line-height: 46px;
        letter-spacing: 0.72px;
    }
}

.descriptionWrapper {
    flex: 1;
    position: relative;
    top: 40px;
}

.descriptionWrapper a {
    display: inline;
}

.subtitle {
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 0.75px;
    margin-bottom: 40px;
}

.description {
    font-size: 21px;
    line-height: 34px;
    letter-spacing: 0.875px;
    margin-bottom: 60px;
}

.advantages {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1338px;
    margin: 0 auto -440px auto;


    @include mobile-size {
        margin-bottom: unset;
        margin-top: 50px;
    }
}

.advantage {
    border-left: 1px solid #979797;
    padding-left: 63px;
    padding-bottom: 30px;
    max-width: 493px;

    @include mobile-size {
        position: inherit !important;
        margin-bottom: 40px;
        padding: 0 15px;
    }
}

.advantageOne {
    position: relative;
    left: -300px;
}

.advantageTwo {
    position: relative;
    right: -350px;
    top: -180px;
}

.advantageThree {
    position: relative;
    left: -175px;
    top: -230px;
}

.advantageFour {
    position: relative;
    right: -475px;
    top: -445px;
}

.advantageNumber {
    font-weight: 500;
    font-size: 200px;
    line-height: 200px;
    letter-spacing: 4px;
    color: $ActionColor;

    @include mobile-size{
        font-size: 100px;
        line-height: 100px;
        letter-spacing: 2px;
    }
}

.advantageTitle {
    font-weight: 500;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 0.75px;
}

.advantageDescription {
    font-weight: 400;
    font-size: 21px;
    line-height: 34px;
    letter-spacing: 0.875px;
}

.howItWorks {
    max-width: 742px;
    margin: 100px auto 100px auto;

    @include mobile-size{
        margin: 15px;
    }
}

.howItWorks h2 {
    font-size: 44px;
    line-height: 54px;
    letter-spacing: 1.1px;
    margin-bottom: 40px;
}

.howItWorks h3 {
    font-size: 25px;
    line-height: 38px;
    letter-spacing: 1.25px;
    margin-bottom: 40px;
}

.howItWorks p {
    font-size: 21px;
    line-height: 34px;
    letter-spacing: 0.875px;
    margin-bottom: 40px;
}

.howItWorks ol {
    list-style: none;
    counter-reset: counter;
    padding-left: 0;
    margin-bottom: 60px;
}

.howItWorks ol li {
    font-weight: 400;
    font-size: 19px;
    line-height: 31px;
    letter-spacing: 0.791667px;
    counter-increment: counter;
    display: flex;
    margin-bottom: 13px;
}

.howItWorks ol li::before {
    content: counter(counter) ". ";
    color: $ActionColor;
    font-size: 21px;
    padding-right: 24px;
}

.howItWorks a {
    display: inline;
}

.supportBlock{
    margin-top: 100px;
}