@import '../../../Assets/Styles/Imports.scss';

.main{
    @include Field;
    display: flex;
    background: transparent;
    color: #90A3B0;
    font-size: 21px;
    font-weight: 400;
    padding: 16px 16px 16px 0;
    width: 100%;
    max-width: 355px;
    border-bottom: 1px solid #3D5463;
    border-radius: unset;

    @include mobile-size{
        font-size: 19px;
        line-height: 31px; 
    }
}

.dropdownMenu {
    top: 22px!important;
    background: linear-gradient(0deg, rgba(15, 15, 15, 0.5), rgba(15, 15, 15, 0.5)), rgba(255, 255, 255, 0.05);
    border: 1px solid #585656;
    box-sizing: border-box;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(13.5914px);
    width: 100%;
    max-height: 330px;
    max-width: 330px;
    padding: 24px 30px 24px 15px;
    overflow-y: auto;

    @include mobile-size{
        min-height: 150px;
    }

    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 10px 10px green;
        border: solid 3px transparent;
    }
    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 10px 10px red;
        border: solid 3px transparent;
    }


}

.dropdownItem {
    display: flex;
    align-items: center;
    height: 40px;
}

.dropdownItem:hover {
    background: #00A76D;
}


.arrowIcon{
    width: 25px;
    padding-left: 26px;
    margin-left: auto;
    user-select: none;
}


@include dark-mode{
    .main {
        border-bottom: 1px solid white;
        color: white;

    }

    .main::placeholder{
        color:#D5D2CD;
    }

    .dropdownItem{
        color: white;
    }
}

@include mobile-size {
    .main {
        max-width: unset;
    }
    .dropdownMenu {
        max-width: unset;
    }
}
