@import "../../../Assets/Styles/Imports.scss";

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 37px;
  position: relative;
}

.carousel {
  z-index: inherit;
}

.slide {
  height: 500px;
  width: 770px;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-blend-mode: normal, normal, multiply, normal;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  position: relative;
  background: url(../../../Assets/Images/Risk/risk-profile-two.jpg),
  linear-gradient(178.54deg, rgba(0, 211, 166, 0.0001) 11.31%, #00a76d 179.68%),
  linear-gradient(188.83deg, rgba(0, 0, 0, 0.0001) -8.96%, #292828 91.38%), #292828;
  z-index: 2;
}

.slideContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 30px;
  padding-left: 40px;
}

.slidePagination {
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}

.slideNumber {
  color: $WhiteColor;
}

.slidePaginationDelimeter {
  display: block;
  background: #979797;
  width: 56px;
  height: 1px;
  margin-left: 10px;
  margin-right: 10px;
}

.slidesNumber {
  color: #948e8d;
}

.slideTitle {
  width: 100%;
  font-weight: 500;
  font-size: 70px;
  line-height: 80px;
  letter-spacing: 1.4px;
  color: $WhiteColor;
  text-align: justify;
  margin-bottom: 20px;
}

.slideAction {
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.5px;
  text-align: left;
  margin-top: 108px;

  @include mobile-size {
    display: none;
  }
}

.slideSwitch {
  position: absolute;
  bottom: 36px;
  right: 30px;
}

.recommended {
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.5px;
  background: $ActionColor;
  border-radius: 20px;
  padding: 6px 19px;
  position: absolute;
  top: 40px;
  right: 40px;
}

.arrowButtonsWrapper {
  width: 770px;
  height: 40px;
  margin: 0 auto;
  position: relative;
  top: -270px;
  z-index: 1;
}

.leftArrowButton,
.rightArrowButton {
  position: absolute;
  top: calc(50% - 40px);
  background: none;
  border: none;
  padding: 0;
}

.leftArrowButton {
  left: -66px;
}

.rightArrowButton {
  right: -66px;
}

.indicator {
  display: none !important;
}

.detailInfoTitle {
  font-weight: 500;
  font-size: 70px;
  line-height: 80px;
  letter-spacing: 1.4px;
  margin-bottom: 60px;
}

.confirmStrategyButton {
  max-width: 250px;
  margin-top: 120px;
}

.closePopupLink {
  display: block;
  margin-top: 60px;
  font-weight: 400;
  font-size: 21px;
  line-height: 34px;
  letter-spacing: 0.875px;
  color: $SecondaryTextColorGray;
}