@import '../../../Assets/Styles/Imports.scss';

.content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-wrap: wrap;
  column-gap: 40px;

  @include mobile-size {
    justify-content: center;
  }
}

.title {
  max-width: 390px;
  text-align: left;
  font-style: normal;
  font-weight: 500;
  font-size: 70px;
  line-height: 80px;
  letter-spacing: 1.4px;
}

.productContainer {
  margin-left: 62px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-wrap: wrap;

  @include mobile-size {
    margin-left: 0;
  }
}


.product {
  width: 360px;
  height: 500px;
  color: $SecondaryTextColor;
  margin-right: 62px;
  float: left;
  padding: 100px 0 0;

  &:hover {
    background: #e0e7f6;
  }
}


.selectorTitle {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  padding: 0 20px;
  margin-bottom: 20px;


  @include mobile-size{
    font-size: 20px;
  }
}

.selectorDescription {
  font-size: 20px;
  padding: 0 30px;
  margin-bottom: 85px;


  @include mobile-size{
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 60px;
  }
}

@include dark-mode {

  .selectorTitle{
    color: #FFF;
  }

  .selectorDescription{
    color: #D5D2CD;
  }
}
