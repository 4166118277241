@import "../../../Assets/Styles/Imports.scss";

.main {
    position: relative;
    min-width: 478px;
    height: 710px;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 38px 41px;
    border-top: 1px solid #585656;
    border-bottom: 1px solid #585656;
    background: linear-gradient(0deg, rgba(15, 15, 15, 0.8), rgba(15, 15, 15, 0.8)), rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(13.5914px);
}


.title {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 1px;
    color: $SecondaryTextColorGray;
}

.type {
    font-size: 19px;
    line-height: 32px;
    letter-spacing: 0.791667px;
    color: $WhiteColor;
}

.amountWrapper {
    margin-top: 26px;
}

.amount {
    font-weight: 500;
    font-size: 44px;
    line-height: 54px;
    letter-spacing: 1.1px;
    color: $WhiteColor;
}

.currency {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 1px;
    color: $WhiteColor;
    padding-left: 11px;
}

.difference {
    color: $ActionColor;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.5px;
    display: flex;
}

.difference img {
    padding-right: 8px;
}

.differenceValue {
    padding-left: 6px;
    padding-right: 6px;
}

.graph {
    margin-top: 150px;
}

.graph img {
    width: 100%;
}

.action {
    position: absolute;
    bottom: 30px;
    right: 30px;
    width: 20px;
    height: 20px;
}

.balanceSelect{
    width: 180px;
    height: 40px;
    border: 1px solid #585656;
    filter: drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.5));
    border-radius: 5px;
    z-index: 10;

    font-size: 14px;
    line-height: 22px;
    margin: 10px 0;

    &>span{
        border-bottom: none !important;
        font-size: 14px;
        line-height: 22px;
        padding: 8px 0 8px 12px;
        height: auto;
        position: relative;

        &>div{
            // margin-left: 35px;
            // padding-left: 0;
            right: 10px;
            top: 10px;
            width: auto;
            position: absolute;
        }
    }
    & a{
        font-size: 14px;
        line-height: 22px;
    }
    &>div{
        top:30px !important;
    }
}

.flex {
    display: flex;
}

.balanceContainer{
    margin-right: 20px;
}

.jc_sb{
    justify-content: space-between;
}

.graph_bg{
    width: 100%;
    height: 300px;
    position: absolute;
    // height: 100%;
    top: 150px;
    & .text{
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
        color: #D5D2CD;
    }
    & .line{
        border-bottom: 1px #979797;
        border-bottom-style: dashed;
        margin: 0;
    }
    & .container{
        margin-bottom: 40px;
    }
    & .months{
        display: flex;
        justify-content: space-evenly;
    }
}
