@import "../../../Assets/Styles/Imports.scss";

.strategyChangeSuccess,
.strategyChangeFailed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
  background: linear-gradient(0deg, #00a76d, #00a76d),
    linear-gradient(188.83deg, rgba(0, 0, 0, 0.0001) -8.96%, #292828 91.38%), #292828;
}

.strategyChangeFailed {
  background: linear-gradient(0deg, #f9635c, #f9635c),
    linear-gradient(188.83deg, rgba(0, 0, 0, 0.0001) -8.96%, #292828 91.38%), #292828;
}

.amount {
  font-weight: 400;
  font-size: 70px;
  line-height: 80px;
  letter-spacing: 1.4px;
}

.amountCurrency {
  margin-left: 18px;
  font-weight: 400;
  font-size: 21px;
  line-height: 34px;
  letter-spacing: 0.875px;
}

.profitFigures {
  display: flex;
  justify-content: center;
  max-width: 350px;
  height: 46px;
  padding: 6px 20px 6px 15px;
  margin-top: 25px;

  background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), rgba(15, 15, 15, 0.4);
  border: 1px solid #585656;
  backdrop-filter: blur(13.5914px);
  border-radius: 23px;

  font-weight: 400;
  font-size: 19px;
  line-height: 32px;
  letter-spacing: 0.8px;
  color: $ActionColor;

  img {
    margin-right: 8px;
  }
}

.stockDown {
  color: $ErrorColor;
  img {
    transform: rotate(180deg);
  }
}

.chartContainer {
  display: flex;
  align-items: center;
  border-top: 1px solid #585656;
  border-bottom: 1px solid #585656;
}

.chartContainer > * {
  border: 0;
  border-radius: 0;
  background: linear-gradient(0deg, rgba(15, 15, 15, 0.8), rgba(15, 15, 15, 0.8)), rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(13.5914px);
}

.board {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), rgba(15, 15, 15, 0.3);
  border: 1px solid #585656;
  backdrop-filter: blur(13.5914px);
  border-radius: 10px;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  row-gap: 100px;
  max-width: 1535px;
  margin: 100px auto 0;

  @include mobile-size{
    margin: 40px auto 0;
    padding: 0 17px;
  }
}

.infoBoard {
  display: flex;
  min-height: 530px;
  column-gap: 50px;

  @include mobile-size{
    display: block;
  }
}

.strategyInfo {
  max-width: 1010px;
}

.panelHeader {
  font-weight: 500;
  font-size: 44px;
  line-height: 54px;
  letter-spacing: 1.1px;

  @include mobile-size{
    font-size: 28px;
    line-height: 40px;
    letter-spacing: 0.7px;
  }
}

.panelTitle {
  margin-top: 30px;
  font-weight: 500;
  font-size: 25px;
  line-height: 38px;
  letter-spacing: 1.25px;

  @include mobile-size{
    margin-top: 0;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: 1.1px;
  }
}

.panelDescription {
  margin-top: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 34px;
  letter-spacing: 0.875px;

  @include mobile-size{
    font-size: 19px;
    line-height: 31px;
    letter-spacing: 0.791667px;
  }
}

.infoBoardRightSide {
  display: flex;
  flex-direction: column;
  row-gap: 50px;

  @include mobile-size{
    row-gap: 0;
  }
}

.riskProtection,
.riskBenefits {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 0.75px;
}

.deposits {
  display: flex;
  column-gap: 46px;

  @include mobile-size{
    display: block;
  }
}

.depositsTitle {
  margin-bottom: 40px;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 0.75px;

  @include mobile-size{
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0.6px;
  }
}

.subtitle {
  font-weight: 500;
  font-size: 25px;
  line-height: 38px;
  letter-spacing: 1.25px;
  margin-bottom: 34px;
}

.showSuccessContainer {
  text-align: center;
  img {
    display: inline-block;
    margin-bottom: 60px;
  }
  h3 {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
}

.warningButton {
  width: 190px;
  height: 50px;
  padding: 0 !important;
}
