@import "../../../Assets/Styles/Imports.scss";

.flex {
    display: flex;
    flex-wrap:wrap;
    justify-content: start;

    div {
        flex-basis: 41%;

    }

    @include mobile-size{
        display: block;
    }

}

.flex > div{
    margin: 1%;
}
.radioWrapper {
    display: flex;

    @include mobile-size{
        display: unset;
    }
}

.radio{
    margin-right: 40px;

    @include mobile-size{
        display: block;
        margin-bottom: 25px;
    }
}

.title {
    font-style: normal;
    font-weight: 500;
    font-size: 70px;
    line-height: 80px;
    letter-spacing: 1.4px;
    color: $WhiteColor;

    @include mobile-size {
        font-size: 40px;
    }
}

.paragraph {
    margin: 0 60px 60px 60px!important;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 0.75px;
    color: $WhiteColor;
}

.number {
    font-style: normal;
    font-weight: 500;
    font-size: 44px;
    line-height: 54px;
    letter-spacing: 1.1px;
    color: $WhiteColor;
    margin-right: 80px;
    flex-basis: 11%!important;
}

.question {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 54px;
    letter-spacing: 1.1px;
    color: $WhiteColor;
}

.line {
    border-color: #948E8D;
}

.relative {
    position: relative;
}

.sufix {
    position: absolute;
    right: 0;
    top: 28px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.5px;
    color: #D5D2CD;
}

@include dark-mode {
    .radio > input::after {
        background-color:  $ActionColor;

        width: 40px;
        height: 40px;
        top: -1px;
        bottom:1px;
        right: 1px;
        left: -1px;
    }

    .radio > input:checked:before {
        background-image: url(../../../Assets/Images/Dashboard/password_check_icon.svg);
        background-repeat: no-repeat;
        width: 21px;
        height: 20px;
        top: 10px;
        right: 7px;
        z-index: 1;
    }

    .radio > input::before {
        border: none;
    }

    .radio > input {
        width: 40px;
        height: 40px;
        border: 1px solid white;
        border-radius: 20px;
    }
}


.labelOptional {
    font-size: 20px;
    margin-bottom: 2px;
  
    @include mobile-size {
      font-size: 18px;
    }
}
  

@include dark-mode {
  
    .labelOptional {
      color: #D5D2CD;
    }
  
    .switch > * {
      color: #90A3B0;
    }
}

.titleAnswer{
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 38px;
    letter-spacing: 1.25px;
    color: $WhiteColor;
}

.salutationWrapper .answerContainer{
    margin-top: 50px;
}

.inputWidth{
    flex-basis: 30%;
    margin-right: 40px;
}

.inputWidth100 {
    flex-basis: 63%;
}

.flex .inputWidth43{
    flex-basis: 43%;
}

.flex .mt35{
    margin-top: 35px;
}

.flex .m0{
    margin: 0;
}

.flex .mr40{
    margin-right: 60px;
}

.newPortfolioBlock {
    margin-top: 60px;
    display: flex;
    align-items: center;
}

.buttonAddPortfolio {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-top: 0;
    margin-right: 16px;

    &::after {
        content: "";
        position: absolute;
        top: 15px;
        left: 12px;
        height: 10px;
        width: 16px;
        background: url("../../../Assets/Images/Btn_right_arrow.svg") no-repeat;
    }
}

.linkAddPortfolio{
  color: $WhiteColor;
  text-decoration: none;

  &:hover{
    color: $WhiteColor;
  }
}