@import '../../../Assets/Styles/Imports.scss';

.slider {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    overflow: hidden;
    overflow-anchor: none;
}

.slides {
    display: flex;

    overflow-x: auto;
    scroll-snap-type: x mandatory;

    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;

    margin-bottom: 20px;
    scrollbar-width: none;

    /*
  scroll-snap-points-x: repeat(300px);
  scroll-snap-type: mandatory;
  */
}
.slides::-webkit-scrollbar {
    display: none;
}
.slides::-webkit-scrollbar-thumb {
    display: none;
}
.slides::-webkit-scrollbar-track {
    display: none;
}

.slides > * {
    scroll-snap-align: center;
    flex-shrink: 0;
    width: 80%;
    // margin-right: 20px;
    margin: 0 7.5px;
    transform-origin: center center;
    transform: scale(1);
    transition: transform 0.5s;
    height: auto;
}

.slides::after{
    content: "";
    display: block;
    width: 0.1px;
    min-width: 0.1px;
}

.slides > *:first-child{
    margin-left: 95%;
}

.slides > *:last-child{
    margin-right: 95%;
}

.author-info {
    background: rgba(0, 0, 0, 0.75);
    color: white;
    padding: 0.75rem;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
}
.author-info > a {
    color: white;
}
// img {
//   object-fit: cover;
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
// }

.indicatorWrapper > a {
    pointer-events: none;
    display: inline-flex;
    width: 0.5rem;
    height: 0.5rem;
    background: $TileBlueColor;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: auto 2.7%;
    position: relative;
}

.indicatorWrapper > a.selected {
    background: $SecondaryTextColor;
}

.indicatorWrapper > a:active {
    top: 1px;
}
.indicatorWrapper > a:focus {
    background: #000;
}

.indicatorWrapper {
    display: flex;
    justify-content: center;

}



////////////// EFFECTS //////////////////////



.scaleEffect > *{
    transform: scale(0.8);
    // transition: transform 0.1;
}

.scaleEffect > *[checked='true']{
    transform: scale(1);
}


// @keyframes smoothScroll {
// 	0% {
// 		transform: scale(0.8);
// 	}
// 	100% {
// 		transform: scale(1);
// 	}
// }