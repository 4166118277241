@import '../../../../Assets/Styles/Imports.scss';

.main{
    margin: auto;
}

.selector {
    width: 100%;
    &:hover {
        cursor: pointer;
    }

}

.selectorBlock {
    margin: 30px auto;
    max-width: 634px;
}

.paragraph {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 38px;
    text-align: center;
    letter-spacing: 1.2px;
    margin-bottom: 60px;

    @include mobile-size{
        font-size: 20px;
        margin-bottom: 40px;
    }

}

.choiceText {
    display: block;
    max-width: 478px;
    font-size: 25px;

    @include mobile-size{
        font-size: 18px;
    }
}
