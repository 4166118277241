@import "../../../../Assets/Styles/Imports.scss";

.popup{
  display: flex;
  padding: 0;
  transform: translate(100px, 0) !important;
  transition: transform 0.2s ease-in;

  @include mobile-size{
    transform: translate(0, 100px) !important;
  }
}

.popup > div{
  height: 100%;
  min-height: 100%;
  @include mobile-size{
    margin-left: unset;
    min-width: unset;
  }
}

:global(.modal.show) .popup{
  transform: none !important;
}

.main {
  max-width: 1485px;

  @include mobile-size {
    max-width: unset;
  }
}

.content {
  max-width: 910px;
  margin: 130px auto 95px;
}

.content > h2 {
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 44px;
  line-height: 54px;
  letter-spacing: 1.1px;
}

.content > h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 0.75px;
}

.paragraph {
  margin-bottom: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 38px;
  letter-spacing: 1.25px;
}

.closeText {
  display: block;
  margin-top: 60px;
}


.subtitle{
  margin-bottom: 10px;
}

.line{
  margin-top: 0;
  margin-bottom: 20px;
}

.listContainer{
  margin-bottom: 40px;
}

.content .downSubtitle{
  font-weight: 400;
  font-size: 19px;
  line-height: 31px;
  letter-spacing: 0.791667px;
}

.paragraphMediumRisk {
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 34px;
  letter-spacing: 0.875px;
  margin-bottom: 9px;

  &:last-of-type {
    margin-bottom: 30px;
  }
}

.listElem{
  font-weight: 400;
  font-size: 19px;
  line-height: 31px;
  letter-spacing: 0.791667px;
  margin-bottom: 20px;
  display: flex;

  &::before{
    content: "";
    background: url(../../../../Assets/Images/Arrow_rIght_list.svg);
    width: 20px;
    height: 20px;
    display: block;
    margin-right: 20px;
    padding-right: 20px;
  }
}
