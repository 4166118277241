@import '../../Assets/Styles/Imports.scss';

.mainText {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include mobile-size {
    flex-direction: column;
  }
}

.title {
  max-width: 770px;
  margin-bottom: 39px;
  text-align: left;
  font-weight: 500;
  font-size: 70px;
  line-height: 80px;
  letter-spacing: 1.4px;

  @include mobile-size {
    font-size: 40px;
    margin-bottom: 17px;
  }
}

.secondaryText {
  max-width: 573px;
  margin-bottom: 60px;
  font-weight: 400;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 0.75px;

  @include mobile-size {
    font-size: 20px;
    margin-bottom: 40px;
  }
}

.valuePanelWrapper {
  flex: 1;
  margin-top: 35px;
  max-width: 769px;
}


.formWrapper{
  max-width: 785px;
  margin: 0 auto;
}

.continueBlock {
  max-width: 1335px;
  margin: 80px auto;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #948E8D;
  backdrop-filter: blur(89.7033px);
}

.continueBlockInner {
  max-width: 785px;
  margin: 0 auto;
  padding-top: 56px;
  padding-bottom: 60px;

  @include mobile-size {
    padding-left: 50px;
    padding-right: 50px;
  }
}

.continueBlock h6 {
  font-weight: 400;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 0.75px;
}

.whiteButton {
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.5px;
  width: 215px;
  margin: 0;
}

.continueBlock .whiteButton {
  margin-top: 55px;
}
