@import '../../../Assets/Styles/Imports.scss';

.main {
    max-width: 783px;
    margin: 96px auto;

    @include mobile-size{
        margin-top: 27px;
        margin-bottom: 0;
    }
}

.title {
    font-size: 44px;
    line-height: 54px;
    letter-spacing: 1.1px;
    padding-bottom: 26px;
    margin: 0;

    @include mobile-size{
        font-size: 28px;
        line-height: 40px;
        letter-spacing: 0.7px;
        padding: 0 18px;
    }
}
