@import '../../../Assets/Styles/Imports.scss';

.main{
  display: flex;
  column-gap: 200px;

  @include mobile-size{
    display: block;
  }
}

.title{
  text-align: left;
}

.subtitle{
  max-width: 604px;
}

.titleSection{
  max-width: 630px;
  flex-shrink: 0;
}

.selectors{
  padding-top: 160px;
  @include mobile-size{
    padding-top: 20px;
  }
}

.surveyAnswerContainer{
  min-width: 635px;

  @include mobile-size{
    min-width: 335px;
  }
}

.surveyAnswerTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #D5D2CD;


}

@include dark-mode{
  .surveyAnswerTitle {
    color: #D5D2CD;
    margin-bottom: 0;
  }
}