@import '../../../Assets/Styles/Imports.scss';

.dashboardPensionCard {
  margin-top: 100px;

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 44px;
    line-height: 54px;
    letter-spacing: 1.1px;
    margin-bottom: 40px;

    @include mobile-size {
      font-size: 20px;
    }
  }

  .description {
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 38px;
    margin: 20px 0;
    letter-spacing: 1.25px;
    color: $WhiteColor;
  }

  .line {
    margin: 100px 0;
    background: #585656;
    border-color: #585656;
    height: 1px;
  }

  .formGroup {
    margin: 40px 0;
  }

  ul {
    list-style: none;
    padding-left: 0;

    li {
      position: relative;
      font-style: normal;
      font-weight: 400;
      font-size: 19px;
      line-height: 31px;
      letter-spacing: 0.79px;
      color: $WhiteColor;
      padding-left: 30px;
      margin-bottom: 20px;

      &:after {
        position: absolute;
        left: 0;
        top: 4px;
        content: '';
        height: 20px;
        width: 20px;
        background: url('../../../Assets/Images/Dashboard/arrow_list.svg');
        background-size: cover;
      }
    }
  }
}

.tdNone{
  text-decoration: none;
}