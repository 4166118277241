@import "../../../Assets/Styles/Imports.scss";

.title {
  font-weight: 700;
  font-size: 70px;
  line-height: 80px;
  letter-spacing: 1.4px;
}

.main {
  display: flex;
  flex-direction: column;
  row-gap: 50px;
  margin: 50px 50px 0;
}

.indicators {
  display: flex;
  column-gap: 50px;
}

.infoPanelTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 44px;
  line-height: 54px;
  letter-spacing: 1.1px;
}

.description {
  margin-top: 30px;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 34px;
  letter-spacing: 0.875px;
}

.downloadButton {
  margin-top: 40px;
}

.infoPanelDescription {
  margin-top: 30px;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 34px;
  letter-spacing: 0.875px;
}

.links {
  display: flex;
  justify-content: space-between;
}