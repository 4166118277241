@import "../../../../Assets/Styles/Imports.scss";

.main {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    padding: 0 8px;
}

.title {
    text-align: left;
    letter-spacing: 1.4px;
    max-width: 700px;
    margin-bottom: 0;

    @include mobile-size {
        font-style: normal;
        font-weight: 500;
        font-size: 36px;
        line-height: 46px;
        letter-spacing: 0.72px;
    }
}


.content {
    width: 100%;
    height: 100%;
    margin: 120px auto 0;
    max-width: 640px;

    @include mobile-size{
        margin: 30px auto 0;
    }
}

.content > h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 44px;
    line-height: 54px;
    letter-spacing: 1.1px;
    margin-bottom: 20px;

    @include mobile-size {
        font-size: 22px;
        line-height: 36px;
        letter-spacing: 0.55px;
        margin-bottom: 16px;
    }
}

.description {
    font-weight: normal;
    font-size: 25px;
    line-height: 38px;
    letter-spacing: 1.2px;
    margin-bottom: 30px;

    @include mobile-size {
        font-size: 20px;
        line-height: 32px;
        letter-spacing: 1px;
        margin-bottom: 20px;
    }
}
