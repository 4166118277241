@import "../../../Assets/Styles/Imports.scss";


.main {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.mainScreenPaddings {
    @include screen-padding;
}

.mainFullWidth {
    @include view-full-width;
}

.main video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.backgroundOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-blend-mode: multiply;
}

.stepper {
    max-width: 760px;
    margin: 0 auto;
    margin-bottom: 100px;
    z-index: 0 !important;

    @include mobile-size{
        margin-bottom: 40px;
    }
}

.backButton {
    width: 40px;
    height: 40px;
    cursor: pointer;
    
    img {
        width: 100%;
    }
}

.header {
    display: flex;
    justify-content: space-between;
}

.logo {
    width: 43%;
    max-width: 180px;
    margin-bottom: 6px;
    z-index: 1;

    @include mobile-size {
        max-width: 77px;
        min-height: 15px;
        margin-bottom: 22px;
    }
}

.content {
    width: 100%;
    margin: auto;
    z-index: 1;
    padding-bottom: 130px;

    @include mobile-size{
        padding-bottom: 0;
        margin: 0;
    }
}

.loader{
    position: fixed;
    display: flex;
    justify-items: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@include dark-mode {
    .main {
        background-image: linear-gradient(39.11deg, #0F0F0F 0.62%, #1F1F1F 101.06%, #252525 101.06%);
    }
}
