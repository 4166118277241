@import "../../../Assets/Styles/Imports.scss";


.title {
    margin-top: 50px;
    font-style: normal;
    font-weight: 700;
    font-size: 70px;
    line-height: 80px;
}

.section {
    margin-bottom: 100px;
}

.deposits {
    display: flex;
    column-gap: 46px;
    row-gap: 46px;
    flex-wrap: wrap;
}

.titleContainer {
    margin-top: 26px;
    margin-bottom: 22px;
}

.amount {
    font-weight: 500;
    font-size: 44px;
    line-height: 54px;
    letter-spacing: 1.1px;
    color: $WhiteColor;
}

.currency {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 1px;
    color: $WhiteColor;
    padding-left: 11px;
}

.difference {
    color: $ActionColor;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.5px;
    display: flex;
    padding: 0 15px;
    width: fit-content;
    margin-bottom: 64px;

    background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), rgba(15, 15, 15, 0.4);
    border: 1px solid #585656;
    backdrop-filter: blur(13.5914px);
    border-radius: 23px;
}

.difference img {
    padding-right: 8px;
}

.differenceValue {
    padding-left: 6px;
    padding-right: 6px;
}

.progressLine {
    margin: 10px 0;
    background: #585656;
    height: 34px;
    position: relative;
    width: 100%;
}

.progressLineGreen {
    background: #00A76D;
    height: 34px;
}

.graph{
    width: 100%;
    padding: 125px 185px;
    border-top: 1px solid #585656;
    border-bottom: 1px solid #585656;
    background: linear-gradient(0deg, rgba(15, 15, 15, 0.8), rgba(15, 15, 15, 0.8)), rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(13.5914px);

    & .title{
        font-weight: 500;
        font-size: 30px;
        line-height: 40px;
        letter-spacing: 0.75px;
        margin-top: 0;
        margin-bottom: 50px;
    }
    & .subtitle{
        font-weight: 500;
        font-size: 44px;
        line-height: 54px;
        letter-spacing: 1.1px;
        margin-bottom: 20px;
    }
    & .caption{
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;        
        letter-spacing: 0.5px;
        color: #D5D2CD;
        margin-top: 20px;
    }
    & .container{
        display: flex;
        justify-content: space-between;
    }
}

.container{
    display: flex;
    justify-content: space-between;
}

.btnTitleContainer{
    text-align: left;
}

.pr0{
    padding-right: 0;
}

.line{
    border-bottom: 1px solid #585656;
    margin: 0;
    margin-bottom: 20px;
    width: 100%;
}

.whiteLine{
    border-bottom: 1px solid;
    margin: 30px 0;
}

.flex{
    display: flex;
}

.paymentsTitle{
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 0.75px;
    margin-bottom: 60px;
}

.paymentsSubtitle{   
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.5px;
    margin-bottom: 20px;
}

.totalTitle{
    font-weight: 500;
    font-size: 25px;
    line-height: 38px;
    letter-spacing: 1.25px;
}

.totalAmount{
    font-weight: 500;
    font-size: 44px;
    line-height: 54px;
    letter-spacing: 1.1px;
}

.totalSubtitle{
    font-weight: 400;
    font-size: 21px;
    line-height: 34px;
    letter-spacing: 0.875px;
}

.remainingAmount{
    font-weight: 500;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 0.75px;
}

.totalContainer{
    min-width: 500px;
    margin-top: 70px;
}

.paymentsContainer{
    min-width: 40%;
    margin-right: 7%;
    margin-left: 7%;
}

.btn{
    margin-top: 0;
    height: 50px;
    padding: 12px 36px;

    &:first-child{
        margin-right: 31px;
    }
}