@import '../../../Assets/Styles/Imports.scss';

.main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  width: 100%;
  max-width: 1070px;
  height: 355px;
  padding: 0 80px 0 137px;

  font-style: normal;
  font-weight: 500;
  font-size: 44px;
  line-height: 54px;
  letter-spacing: 1.1px;
  color: $WhiteColor;
  text-decoration: none;

  background: linear-gradient(211.77deg, rgba(64, 113, 87, 0.8) -0.77%, rgba(0, 167, 109, 0.8) 101.68%);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(16.3097px);
  border-radius: 40px 0 0 40px;
  border: none;

  &:hover {
    color: $WhiteColor;
    text-decoration: none;
  }

  @include mobile-size{
    font-size: 28px;
    line-height: 40px;
    letter-spacing: 0.7px;
    height: auto;
    padding: 20px;
    margin-left: 20px;
  }

}

.arrowContainer {

  display: flex;
  justify-content: center;
  align-items: center;

  width: 120px;
  height: 120px;
  border: 1px solid $WhiteColor;
  border-radius: 100%;

  img {
    width: 30px;

    @include mobile-size{
      width: 10px;
    }
  }

  
  @include mobile-size{
    width: 40px;
    height: 40px;
    margin-right: 20px;
  }
}