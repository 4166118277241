@import '../../../Assets/Styles/Imports.scss';

.intro {
    margin: 182px 168px 182px 100px;
    display: flex;

    @include mobile-size{
        margin: 15px;
        display: block;
    }
}

.title {
    font-weight: 500;
    font-size: 70px;
    line-height: 80px;
    letter-spacing: 1.4px;
    flex: 1;
    text-align: left;

    @include mobile-size{
        font-size: 36px;
        line-height: 46px;
        letter-spacing: 0.72px;
    }
}

.descriptionWrapper {
    flex: 1;
    position: relative;
    top: 40px;
}

.subtitle {
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 0.75px;
    margin-bottom: 40px;
}

.description {
    font-size: 21px;
    line-height: 34px;
    letter-spacing: 0.875px;
    margin-bottom: 60px;
}

.actionsList {
    display: flex;
    column-gap: 53px;
    margin: 60px 53px 100px 53px;
}

.squredCorner {
    border-radius: 0;
}

.mt80 {
    margin-top: 80px;
}

.downloadButton {
    margin-top: 60px;
}