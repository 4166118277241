@import '../../../Assets/Styles/Imports.scss';

.main {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 155px 83px 83px;

  min-height: 240px;
  min-width: 480px;

  background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), rgba(15, 15, 15, 0.3);
  border: 1px solid #585656;
  backdrop-filter: blur(13.5914px);
  border-radius: 10px;

  @include mobile-size{
    padding: 28px 20px 54px 20px;
    min-width: auto;
    margin-bottom: 20px;
  }

}

.mainIcon {
  position: absolute;
  top: 30px;
  left: 80px;
  width: 80px;
  height: 80px;

  @include mobile-size{
    position: relative;
    top: 0;
    left: 0;  
    width: 60px;
    height: 60px;
    margin-bottom: 27px;
  }
}

.detailsIcon {
  position: absolute;
  right: 40px;
  bottom: 40px;
  width: 20px;
  height: 4px;

  img {
    width: 100%;
  }

  font-size: unset;
  line-height: unset;
}


.smallPanel {
  padding: 155px 30px 30px;

  .mainIcon {
    left: 30px;
    @include mobile-size{
      left: 0;
    }
  }

  .detailsIcon {
    right: 20px;
  }

  @include mobile-size{
    padding: 28px 20px 54px 20px;
  }
}