@import '../../../../Assets/Styles/Imports.scss';

.main {
    margin: auto;
    position: relative;
    padding: 60px 130px;

    @include mobile-size{
        padding: 16px;
    }
}

.title {
    font-style: normal;
    font-weight: 500;
    font-size: 50px;
    line-height: 62px;
    margin-bottom: 20px;
    text-align: center;

    @include mobile-size {
        margin-bottom: 20px;
        line-height: 42px;
        font-size: 30px;
    }
}

.subTitle {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 0.75px;
    color: $WhiteColor;
    padding-bottom: 100px;
}

.accordion {
    padding-top: 25px;
    max-width: 765px;
}

.accordionDescription {
    font-size: 21px;
    line-height: 34px;
    letter-spacing: 0.875px;
}

.accordtionTitle {
    margin-top: 50px;
    margin-bottom: 30px;
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 34px;
    letter-spacing: 0.875px;
    color: $WhiteColor;
    border-bottom: 1px solid #948E8D;

    @include mobile-size {
        width: 100%;
    }
}

.blockTitle {
    margin-top: 10px;
    margin-bottom: 30px;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 34px;
    letter-spacing: 0.875px;
    color: $WhiteColor;
    border-bottom: 1px solid #948E8D;

    @include mobile-size {
        width: 100%;
    }
}

.accordionContainer {
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    row-gap: 50px;
}

.table {
    td {
        min-width: 250px;
        padding-bottom: 9px;

        @include mobile-size {
            min-width: 100%;
            padding-left: 5px;
            padding-right: 5px;
        }
    }
}

.tableRow {
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 26px;
    letter-spacing: 0.5px;
    color: $WhiteColor;
}

.whiteIcon {
    filter: brightness(0) invert(1);
}

.portfolioContainer {
    max-width: 765px;
    width: 80%;

    @include mobile-size {
        width: 100%;
    }

    .portfolioMainTitle {
        font-style: normal;
        font-weight: 700;
        font-size: 25px;
        line-height: 38px;
        letter-spacing: 1.25px;
        padding-bottom: 20px;
    }

    .portfolioNumber {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        margin-top: 27px;
        letter-spacing: 1px;
        color: #D5D2CD;
    }

    .portfolioName {
        font-style: normal;
        font-weight: 700;
        font-size: 21px;
        line-height: 26px;
        letter-spacing: 0.5px;
        color: $WhiteColor;
        margin-bottom: 20px;
    }



    .contentPortfolioDetailsMenu {
        position: absolute;
        z-index: 5;
        background: #000;
        width: 277px;
        top: 0;
        right: 0;
        padding: 25px;

        @include mobile-size {
            position: relative;
            width: 100%;
        }

        img {
            float: right;
        }

    }

    .contentPortfolioDetailsMenuLink {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        color: $WhiteColor;
        padding: 5px 15px;

        &:hover {
            background: $ActionColor;
            border-radius: 2px;
            cursor: pointer;
        }

    }
}

.dropdownContainer {
    display: flex;
    justify-content: flex-end;
}

.checkbox {
    color: $WhiteColor;
}

.grayLine {
    height: 1px;
    width: 100%;
    background: $GrayLine;
}

.grayLinePortfolio {
    height: 1px;
    width: 100%;
    background: $GrayLine;
    margin-top: 40px;
}

.list {

    padding-left: 0;

    li {
        list-style: none;
        position: relative;
        padding-bottom: 20px;
        padding-left: 30px;

        &:before {
            content: '';
            top: 2px;
            position: absolute;
            left: 0;
            background: url('./../../../../Assets/Images/Arrow_rIght_list.svg');
            width: 20px;
            height: 20px;
        }
    }
}

.downloadButtonsWrapper {
    display: inline-flex;
    margin-top: 40px;
    column-gap: 40px;

    @include mobile-size{
        display: block;
    }
}

.downloadButton {
    white-space: nowrap;
}
.main {
    border: 1px solid #948E8D;
    box-sizing: border-box;
    @include mobile-size{
        margin-bottom: 20px;
    }
}

.bg {
    content: '';
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(89.7033px);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}
