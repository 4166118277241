@import '../../../Assets/Styles/Imports.scss';

.list {
    margin: 40px 0 20px 0;  
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.5px;
    color: $WhiteColor;
    list-style-image: url('../../../Assets/Images/Dashboard/password_check_icon.svg');
}

.active {
   
}

.button {
    width: 275px;
    margin-bottom: 35px;
}