@import "../../../Assets/Styles/Imports.scss";

.container {
    display: flex;
    position: relative;
    width: 100%;
    height: 5px;
    background-color: #bcced9;
    border-radius: 9px;
    margin: auto;
}
.progressDiv {
    background-color: #90a3b0;
    height: 100%;
    border-radius: 5px;
    pointer-events: none;
}

.thumbDiv {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 10px;
    right: 10px;
    pointer-events: none;
}

.thumbDiv > span {
    position: absolute;
    background: white;
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    border-radius: 50%;
    top: -7px;
    margin-left: -10px;
    pointer-events: none;
    touch-action: none;
    user-select: none;
    box-shadow: 0 2px 7px rgba($color: $PrimaryTextColor, $alpha: 0.2);
}

.slider {
    display: flex;
    position: absolute;
    opacity: 0;
    width: 100%;
    margin-top: -7px;
    height: 20px;
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
}

@mixin runnable-track {
    background: transparent;
    height: 30px;
    pointer-events: none;
}

.slider::-webkit-slider-runnable-track {
    @include runnable-track();
}

.slider::-moz-range-track {
    @include runnable-track();
}

.slider::-ms-track {
    @include runnable-track();
}

@mixin thumb {
    -webkit-appearance: none;
    appearance: none;
    background: white;
    width: 20px;
    height: 20px;
    border: none;
    border-radius: 50%;
    box-shadow: 0 2px 7px rgba($color: $PrimaryTextColor, $alpha: 0.2);
}

.slider::-webkit-slider-thumb {
    @include thumb();
}

.slider::-moz-range-thumb {
    @include thumb();
}

@include dark-mode {
    .container {
        background-color: #585656;
        box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.5);
    }

    .progressDiv {
        background: linear-gradient(211.77deg, #407157 -0.77%, #00a76d 101.68%);
    }

    .thumbDiv > span{
        background: #00a76d;
        box-shadow: 0px 2px 7px rgba(40, 56, 71, 0.2);
    }

}
