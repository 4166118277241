@import "../../../Assets/Styles/Imports.scss";

.main {
    height: 340px;
    max-width: 478px;
    background: linear-gradient(178.54deg, rgba(0, 211, 166, 0.0001) 11.31%, #00A76D 179.68%), url(../../../Assets/Images/Risk/risk-profile-one.jpg), linear-gradient(188.83deg, rgba(0, 0, 0, 0.0001) -8.96%, #292828 91.38%), #292828;
    background-blend-mode: normal, normal, multiply, normal;
    background-repeat: no-repeat;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background-size: cover;
    position: relative;
    cursor: pointer;
    color: white;

    &:hover {
        color: $ActionColor
    }

    @include mobile-size{
        margin-bottom: 20px;
        height: auto;
        min-height: 220px;
        max-width: 100%;
    }
}

.title {
    display: inline-block;
    font-weight: 500;
    font-size: 44px;
    line-height: 54px;
    letter-spacing: 1.1px;
    padding-top: 78px;
    padding-left: 43px;
    padding-right: 43px;

    @include mobile-size{
        font-size: 28px;
        line-height: 40px;
        letter-spacing: 0.7px;
        padding-top: 39px;
        padding-left: 26px;
        padding-right: 26px;
    }
}

.followLink {
    position: absolute;
    right: 32px;
    bottom: 22px;
}
