@import "../../../Assets/Styles/Imports.scss";

.tax {
  display: flex;
  flex-direction: column;
  min-height: 900px;
  border-radius: unset;
  padding: 74px 140px 54px 140px;

  hr {
    margin-top: 70px;
    margin-bottom: 62px;
  }

  @include mobile-size{
    padding: 20px 15px;
  }
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 44px;
  line-height: 54px;
  letter-spacing: 1.1px;
  max-width: 485px;
  margin-bottom: 0;

  @include mobile-size{
    font-size: 28px;
    line-height: 40px;
    letter-spacing: 0.7px;
  }
}

.optionsSection {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @include mobile-size{
    display: block;
  }
}

.selectContainer {
  display: flex;
  justify-content: flex-end;
  column-gap: 60px;
  width: 100%;

  @include mobile-size{
    display: block;
  }
}

.select {
  width: 100%;
  max-width: 355px;
}

.calculationSection {
  display: flex;
  justify-content: space-between;

  @include mobile-size{
    display: block;
  }
}

.withdrawTaxContainer {
  width: 100%;
  max-width: 350px;
  min-height: 170px;
  border-bottom: 1px solid $WhiteColor;
}

.withdrawTitle {
  font-weight: 400;
  font-size: 21px;
  line-height: 34px;
  letter-spacing: 0.875px;
}

.withdrawTax {
  margin-top: 23px;

  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 0.75px;
}

.diagramSection {
  display: flex;
  justify-content: stretch;
  margin-top: 70px;
  min-height: 450px;
  background: #292828;

  @include mobile-size{
    display: block;
    padding: 25px;
  }
}

.taxSavings {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;

  @include mobile-size {
    display: block;
  }
}

.taxSavingsTitle {
  font-weight: 400;
  font-size: 30px;
  letter-spacing: 0.75px;

  @include mobile-size{
    margin-top: 15px;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: 1.1px;
  }
}

.taxSavingsAmount {
  font-weight: 500;
  font-size: 44px;
  line-height: 54px;
  letter-spacing: 1.1px;
}

.diagram {
  display: flex;
  align-items: center;
  width: 50%;
}

.diagramContainer {
  position: relative;
  width: 100%;
  max-width: 372px;
}

.savingsPercent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  font-size: 30px;
  letter-spacing: 0.75px;
}

