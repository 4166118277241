@import "../../../Assets/Styles/Imports.scss";

.message {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 20px;

    &:hover {
        background: linear-gradient(0deg, rgba(15, 15, 15, 0.6), rgba(15, 15, 15, 0.6)), rgba(255, 255, 255, 0.05);
        box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(13.5914px);
        .downloadLink {
            color: $ActionColor;
        }
    }
}

.message + .message {
    border-top: 1px solid #585656;
}

.newMessage {
    background: $ActionColor;
    padding: 1px 10px;
    border-radius: 20px;
}

.messageDate {
    display: inline-block;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 1px;
    margin-bottom: 11px;
}

.messageTitle {
    font-size: 19px;
    line-height: 32px;
    letter-spacing: 0.791667px;
    color: $WhiteColor;
    margin-bottom: 10px;
}

.downloadLink {
    display: flex;
    align-items: center;
    align-content: flex-start;
    max-width: 250px;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 1px;
    color: #948E8D;
    cursor: pointer;

}

.downloadLink::before {
    display: inline-block;
    content: "";
    width: 16px;
    height: 16px;
    margin-right: 10px;
    background-image: url(../../../Assets/Images/Dashboard/Documents/download_icon.svg);
    background-size: contain;
}
