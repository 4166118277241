@import "../../../Assets/Styles/Imports.scss";

.main {
  position: relative;
  background-color: $BorderGrayColor;
  color: $WhiteColor;
  border: none;
  border-radius: 16px;
  padding: 8px 20px;
  text-decoration: none;
  display: block;
  text-align: center;
  width: 100%;
  margin-top: 55px;
  font-weight: 500;
  font-size: 18px;
  line-height: 34px;

  letter-spacing: 0.5px;

  &[disabled] {
    cursor: default;
    color: $WhiteColor;
    background: $BorderLightGrayColor;
    pointer-events: none;
  }

  &:hover {
    color: $WhiteColor;
  }
}

.withArrow {
  padding-right: 45px;

  &:after {
    content: "";
    position: absolute;
    width: 22px;
    height: 12px;
    background: url('../../../Assets/Images/Btn_right_arrow_desktop.svg') no-repeat;
    top: 20px;
    right: 16px;

    @include mobile-size {
      background: url('../../../Assets/Images/Btn_right_arrow_desktop.svg') no-repeat;
      width: 22px;
      height: 12px;
      top: 20px;
      right: 16px;
    }

  }
}

@include dark-mode {
  .main {
    background: $ActionColor;

    &[disabled] {
      background: #948e8d;
    }
  }
}
