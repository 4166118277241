@import '../../../Assets/Styles/Imports.scss';

.main {
    position: relative;
    background-color: rgba(255, 255, 255, 0.1);
    color: black;
    width: 100%;
    max-width: 930px;
    min-height: 100%;
    padding: 25px 20px;
    border-radius: 20px 0 0 20px;
    margin-right: 0 !important;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(89.7033px);

    @include mobile-size{
        width: 100% !important;
        max-width: unset !important;
        margin-right: unset !important;
        height: unset;
        border-radius: 10px 10px 0 0;
    }
}


.popup{
    align-items: flex-end;
    justify-content: right;
    margin-left: auto;
    height: 100%;
    width: unset;
    padding: 0;
    transform: translate(100px, 0) !important;
    transition: transform 0.2s ease-in;

    @include mobile-size{
        transform: translate(0, 100px) !important;
    }
}

.popup > div{
    height: 100%;
    width: unset;
    max-height: unset;
    margin-left: auto;
    @include mobile-size{
        height: 100%;
        margin-left: unset;
    }
}

:global(.modal.show) .popup{
    transform: none !important;
}


.actionBar{
    text-align: right;
}


.actionBar > img{
    cursor: pointer;
    width: 27px;
    user-select: none;
    @include mobile-size{
        width: unset;
    }
}

@include dark-mode {
    .main {
        color: $WhiteColor;
    }
}
