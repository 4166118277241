@import "../../../Assets/Styles/Imports.scss";

.container {
  display: flex;
  column-gap: 61px;

  @include mobile-size {
    flex-direction: column;
    column-gap: unset;
    row-gap: 20px;
    margin-top: 150px;
  }
}

.quotes {
  display: flex;
  flex: 8 1 65px;
  flex-direction: column;
  align-items: flex-end;
  pointer-events: none;
  user-select: none;

  @include mobile-size {
    align-items: flex-start;
    flex: unset;
  }
}

.quotes > img {
  transform: translateY(-11px);
  width: 65px;

  @include mobile-size {
    width: 29px;
  }
}

.title {
  text-align: unset;
  font-weight: 400;
  font-style: normal;
  font-size: 44px;
  line-height: 54px;
  letter-spacing: 1.1px;
  color: $PrimaryTextColor;
  margin-bottom: 40px;

  @include mobile-size {
    margin-bottom: 40px;
    font-size: 28px;
    line-height: 40px;
  }
}

.main {
  flex: 0 2 900px;

  @include mobile-size {
    flex: unset;
  }
}

.content {
  font-size: 25px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 1.25px;
}

@include dark-mode {
  .title, .main {
    color: #FFF;
  }
}

