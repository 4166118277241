@import '../../../../Assets/Styles/Imports.scss';

.container {
    display: flex;
    column-gap: 50px;
    margin-top: 116px;
    margin-left: 92px;
    max-width: 1297px;
    justify-content: space-between;

    @include mobile-size{
        display: block;
        margin: 0;
    }
}

.leftSideContainer {
    width: 100%;
    max-width: 610px;
}

.title {
    display: block;
    text-align: left;
    max-width: 500px;
    @include mobile-size{
        font-size: 30px;
    }
}

.description {
    font-weight: 500;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 0.75px;

    @include mobile-size{
        font-size: 22px;
        line-height: 32px;
        letter-spacing: 1.1px;
        margin-bottom: 40px;
    }
}

.faqContainer {
    float: left;
    width: 100%;
    max-width: 478px;

    @include mobile-size{
        float: none;
    }
}

@include dark-mode {
    .passReqItem {
        &:before {
            filter: invert(100%);
        }
    }
}
