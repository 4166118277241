@import "../../../Assets/Styles/Imports.scss";


.main {
  width: 100%;
  margin: auto;
  padding: 0;
  border: 1px solid #585656;
  box-sizing: border-box;
  border-radius: 20px;
  max-width: 770px;

}

.additionalInfo {
  animation: fade-in 0.5s ease;
}

.w100 {
  width: 100%;
  display: inline-block;
}

.content {
  padding: 85px 44px 50px 40px;

  @include mobile-size {
    padding: 30px 15px;
  }
}

.searchLabel {
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 34px;
  letter-spacing: 0.833333px;
  color: $BlackColor;

  span {
    margin-left: 13px;
  }

  @include mobile-size {
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.75px;
  }
}

.label {
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.9px;
  color: #948e8d;
  margin-bottom: 30px;
}

.labelsRow {
  display: flex;
  column-gap: 40px;
}

@include dark-mode {
  .main {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), rgba(15, 15, 15, 0.4);
    backdrop-filter: blur(13.5914px);
  }

  .label,
  .paragraph,
  .title,
  .subtitle,
  .searchLabel {
    color: #fff;
  }
}

@keyframes fade-in {
  0% {
    transform: translateY(-40px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);

    opacity: 1;
  }
}