@import '../../../Assets/Styles/Imports.scss';

.documentsDashboard {

  margin-bottom: 60px;

  @include mobile-size{
    margin-bottom: 0;
  }
  
  .documentDashboardContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 25px;

    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), rgba(15, 15, 15, 0.4);
    backdrop-filter: blur(48.9291px);
    border-radius: 5px 4px 4px 4px;
    padding: 15px;

    cursor: pointer;

    @include mobile-size{
      flex-direction: column;
      min-height: 187px;
      padding: 20px 30px;
    }

    &:hover {
      background: linear-gradient(0deg, rgba(15, 15, 15, 0.6), rgba(15, 15, 15, 0.6)), rgba(255, 255, 255, 0.05);
      border: 1px solid #585656;
      box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(13.5914px);
      padding: 14px;

      .documentName {
        color: $ActionColor;
      }
    }
  }

  .documentsTitle {
    padding-bottom: 10px;
    border-bottom: 1px solid #585656;
  }

  .documentIcon {
    width: 22px;
    height: 30px;
    margin: 0 15px;

    @include mobile-size{
      margin-right: 95%;
      margin-left: 0;
      margin-bottom: 10px;
      display: block;
    }
    &:last-child{
      margin-left: 95%;
    }
  }

  .documentName {
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 32px;
    letter-spacing: 0.791667px;
    color: $WhiteColor;
  }

  .documentDate {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 1px;
    color: #948E8D;
  }

  .active {
    background: #00A76D;
    border-radius: 13px;
    padding: 2px 15px;
    color: $WhiteColor;
  }

}
