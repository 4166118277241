@import "../../../Assets/Styles/Imports.scss";

.main {
    position: relative;
    min-width: 478px;
    height: 400px;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 38px 41px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), rgba(15, 15, 15, 0.4);
    border: 1px solid #585656;
    backdrop-filter: blur(13.5914px);
    border-radius: 10px;
    cursor: pointer;

    @include mobile-size{
        min-width: 341px;
        padding: 0;
        min-height: 300px;
        height: auto;
        margin-bottom: 20px;
    }
}


.title {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 1px;
    color: $SecondaryTextColorGray;

    @include mobile-size{
        margin: 21px 29px 0 29px;
    }
}

.type {
    font-size: 19px;
    line-height: 32px;
    letter-spacing: 0.791667px;
    color: $WhiteColor;

    @include mobile-size{
        margin: 0 29px 20px 29px;
    }
}

.amountWrapper {
    margin-top: 26px;
}

.amount {
    font-weight: 500;
    font-size: 44px;
    line-height: 54px;
    letter-spacing: 1.1px;
    color: $WhiteColor;
}

.currency {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 1px;
    color: $WhiteColor;
    padding-left: 11px;
}

.difference {
    color: $ActionColor;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.5px;
    display: flex;
}

.difference img {
    padding-right: 8px;
}

.differenceValue {
    padding-left: 6px;
    padding-right: 6px;
}

.graph {
    margin-top: 20px;
}

.graph img {
    width: 100%;
}

.action {
    position: absolute;
    bottom: 30px;
    right: 30px;
    width: 20px;
    height: 20px;
}
