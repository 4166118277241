@import '../../../Assets/Styles/Imports.scss';

.dashboardTitle {
    margin: 90px 0 40px 0;
    font-style: normal;
    font-weight: 500;
    font-size: 44px;
    line-height: 54px;
    text-align: left;
    letter-spacing: 1.1px;
    color: $WhiteColor;

    @include mobile-size{
        font-size: 30px;
        margin: 0 0 20px 0;
    }
}
