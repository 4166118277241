@import "../../../Assets/Styles/Imports.scss";

.passwordBlock {
  margin: auto;
  text-align: center;
  padding-top: 100px;
  max-width: 300px;
  color: $BlackColor;
}

.logo {
  margin-bottom: 20px; 
}

@include dark-mode {
  .passwordBlock {
    color: #FFF;
  }
}