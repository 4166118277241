@import '../../../Assets/Styles/Imports.scss';

.splitView {
    column-gap: 60px;
    
    @include mobile-size {
        display: block;
    }
}

.titleSection {
    flex-shrink: 0;
    max-width: 700px;
}

.amount {
    font-style: normal;
    font-weight: 500;
    font-size: 44px;
    line-height: 54px;
    letter-spacing: 1.1px;
    color: $WhiteColor;
}

.contentSection p {
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 34px;
    letter-spacing: 0.875px;
    color: $WhiteColor;
    margin-bottom: 40px;
}

.amountText {
    font-family: 'Modern Era';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 38px;
    letter-spacing: 1.25px;
    color: $WhiteColor;
}

.line {
    border: 1px solid #FFFFFF;
    margin: 30px 0;
}