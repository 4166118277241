@import '../../../Assets/Styles/Imports.scss';

.wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox {
  height: 0;
	width: 0;
	visibility: hidden;
}

.switch {
	text-indent: -9999px;
	width: 50px;
	height: 4px;
	background: #90a3b0;
	display: block;
	border-radius: 15px;
	position: relative;
  margin-left: 6px;
  margin-right: 20px;
}

.content {
  font-size: 20px;

  @include mobile-size {
    font-size: 18px;
  }
}

.switch:after {
	content: '';
	position: absolute;
	top: -13px;
	left: -6px;
	width: 30px;
	height: 30px;
	background: white;
	border-radius: 20px;
  transition: 0.15s;
  box-shadow: 0 2px 7px rgba($color: $PrimaryTextColor, $alpha: 0.2);
}

.checkbox:checked + .switch:after {
	left: calc(100% + 6px);
	transform: translateX(-100%);
}

@include dark-mode {
  .checkbox {
    background: #948E8D;
  }

  .switch {
    background: #948E8D;
  }

  .switch:after {
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.4);
    background: #948E8D;
  }

  .checkbox:checked + .switch {
    background: #00A76D;
  }

  .checkbox:checked + .switch:after {
    background: #00A76D;
  }


  .content {
    color: white;
  }
}
