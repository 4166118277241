@import "../../../Assets/Styles/Imports.scss";

.salutationWrapper .answerContainer{
    margin-top: 50px;
}

.titleAnswer{
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 38px;
    letter-spacing: 1.25px;
    color: $WhiteColor;
}


.relative {
    position: relative;
}

.sufix {
    position: absolute;
    right: 0;
    top: 28px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.5px;
    color: #D5D2CD;
}

.ansferLine{
    border-color: #948E8D;
    margin: 60px 0;
}

.titleAnswer{
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 38px;
    letter-spacing: 1.25px;
    color: $WhiteColor;
}

.labelOptional {
    font-size: 20px;
    margin-bottom: 2px;
  
    @include mobile-size {
      font-size: 18px;
    }
}
  

@include dark-mode {
  
    .labelOptional {
      color: #D5D2CD;
    }
  
    .switch > * {
      color: #90A3B0;
    }
}


.flex {
    display: flex;
    flex-wrap:wrap;
    justify-content: start;

    div {
        flex-basis: 41%;

    }

    @include mobile-size{
        display: block;
    }

}


.inputWidth{
    flex-basis: 30%;
    margin-right: 40px;
}

.inputWidth100 {
    flex-basis: 63%;
}

.newPortfolioBlock {
    margin-top: 60px;
    display: flex;
    align-items: center;
}

.buttonAddPortfolio {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-top: 0;
    margin-right: 16px;

    &::after {
        content: "";
        position: absolute;
        top: 15px;
        left: 12px;
        height: 10px;
        width: 16px;
        background: url("../../../Assets/Images/Btn_right_arrow.svg") no-repeat;
    }
}

.linkAddPortfolio{
    color: $WhiteColor;
    text-decoration: none;
  
    &:hover{
      color: $WhiteColor;
    }
  }