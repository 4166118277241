@import "../../../Assets/Styles/Imports.scss";

.contentStyle{
    max-width: unset;
    padding: 20px;
    margin: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
}

.contentStyle > div:first-child{
    border: unset;
}

.contentStyle > div{
    display: unset;
    background-color: unset;
    max-height: 80%;
    min-width: fit-content;
}

.contentStyle > div > *{
    // width: 100%;
    // max-width: 1000px;
    margin: auto;
}


.popUpContent{
    width: 763px;
}
.wrapperDiv{
    padding: 24px 65px;
}

:global(.fade.modal.show){
    padding: 0 !important;
}