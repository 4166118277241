@import '../../../Assets/Styles/Imports.scss';

.label {
    display: flex;
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;

    @include mobile-size {
        line-height: 26px;
    }

}

.label > input {
    appearance: none;
    -webkit-appearance: none;
    flex: none;
    position: relative;
    background: transparent;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border: none;

    @include mobile-size {
        width: 26px;
        height: 26px;
    }
}

.label > input::before {
    content: "";
    background: transparent;
    border: 1px solid $BorderGrayColor;
    border-radius: 3px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;

}

.label > input::after {
    content: "";
    background: transparent;
    border: unset;
}

.label > input:checked {
    background: transparent;
    border: unset;
}

.label > input:checked::after {
    position: absolute;
    top: 10%;
    bottom: 10%;
    left: 10%;
    right: 10%;
    background-color: $BorderGrayColor;
    background-image:  url('../../../Assets/Images/Checbox_ok.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px auto;
    border: none;
}

@include dark-mode{

    .label > input::before {
        border: 1px solid #00A76D;
    }

    .label > input:checked::after{
        background-color: #00A76D;
    }

}
