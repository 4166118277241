
@import '../../../Assets/Styles/Imports.scss';


.main{
    display: flex;
    column-gap: 200px;

    @include mobile-size{
        display: block;
        column-gap: 0;
    }
}

.title{
    text-align: left;
}

.subtitle{
    max-width: 604px;
}

.titleSection{
    max-width: 630px;
    flex-shrink: 0;
}

.selectors{
    padding-top: 160px;

    @include mobile-size{
        padding-top: 20px;
    }
}