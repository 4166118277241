@import "../../../Assets/Styles/Imports.scss";

.main {
    color: #292828;
    border: none;
    padding: 8px 20px;
    text-decoration: none;
    display: block;
    text-align: center;
    width: 100%;
    padding: 14px 20px;
    margin-top: 55px;
    font-weight: bold;
    font-size: 16px;
    box-sizing: border-box;
    border-radius: 16px;
    border: 1px solid #292828;
    background: none;

    &[disabled] {
      color: $WhiteColor;
      background: #BCCED9;
      pointer-events: none;
      cursor: default;
    }
}

@include dark-mode {

  .main {
    color: #fff;
    border-color: #FFF;
  }

}