@import "../../../Assets/Styles/Imports.scss";

.flex {
    display: flex;
    justify-content: space-between;
    font-family: 'CeraPro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.5px;
    color: $PrimaryTextColor;
}

.name {
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 38px;
    letter-spacing: 1.25px;
}

.accordionToggle {
    border: 0;
    background: none;
    width: 100%;
    text-align: left;
    border-bottom: 1px solid $GrayLine;
    padding: 15px 0;
    font-family: 'CeraPro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.5px;
    color: $BorderGrayColor;
}

.grayLine {
    height: 1px;
    width: 100%;
    background: $GrayLine;
}

@include dark-mode {
    .name {
        color: $WhiteColor
    }
}
    