@import "../../../Assets/Styles/Imports.scss";

.main {
    display: flex;
    flex-direction: column;
    column-gap: 16px;
    margin:30px 0;
    padding: 50px 50px;
    border-radius: 20px;
    cursor: pointer;
    position: relative;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), rgba(41, 40, 40, 0.2);
    border: 1px solid #585656;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(13.5914px);

    @include mobile-size {
        padding: 20px;
        padding-top: 85px;
        min-height: auto;
        border-radius: 10px;
        column-gap: 0;
        margin: 10px 0;
    }

    &:hover {
        color: #00A76D;
    }
}

.main * {
    user-select: none;
}

.content {
    height: 100%;
    padding-right: 40px;
    @include mobile-size {
        padding-bottom: 40px;
    }
}

.arrowContainer {
    margin-left: auto;
    margin-top: auto;
    margin-bottom: 0;
    position: absolute;
    bottom: 20px;
    right: 20px;
}

.selectorIconContainer {
    width: 40px;
    position: absolute;
    top: 16px;
    left: 13px;

    @include mobile-size {
        left: 23px;
    }
}

.selectorIconContainer > img {
    width: 100%;
}

.arrowContainer > img {
    width: 30px;
}

@include dark-mode {
    .main {
        &:hover {
            background: linear-gradient(0deg, rgba(15, 15, 15, 0.6), rgba(15, 15, 15, 0.6)), rgba(255, 255, 255, 0.05);
        }
    }
}
