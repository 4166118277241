@import "../../../Assets/Styles/Imports.scss";

.relative {
    position: relative;
}

.flex .inputWidth43{
    flex-basis: 43%;
}

.flex .m0{
    margin: 0;
}

.flex .mr40{
    margin-right: 60px;
}

.sufix {
    position: absolute;
    right: 0;
    top: 28px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.5px;
    color: #D5D2CD;
}

.labelOptional {
    font-size: 20px;
    margin-bottom: 2px;
  
    @include mobile-size {
      font-size: 18px;
    }
}
  

@include dark-mode {
  
    .labelOptional {
      color: #D5D2CD;
    }
  
    .switch > * {
      color: #90A3B0;
    }
}

.flex {
    display: flex;
    flex-wrap:wrap;
    justify-content: start;

    div {
        flex-basis: 41%;

    }

    @include mobile-size{
        display: block;
    }

}

.flex > div{
    margin: 1%;
}
.radioWrapper {
    display: flex;

    @include mobile-size{
        display: unset;
    }
}