.passReqList {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 15px;
    column-gap: 30px;
    max-width: 500px;
    list-style-type: none;
    letter-spacing: 0.5px;
    padding: 0;
}

.passReqItem {
    position: relative;
    min-width: 230px;
    padding-left: 37px;

    &:before {
        position: absolute;
        content: "";
        width: 20px;
        height: 20px;
        left: 0;
        background-image: url("../../../Assets/Images/Checkmark-little.svg");
        background-size: 20px 20px;
    }
}

.notMatched {
    &:before {
        background-image: none;
    }
}
