@import "./Fonts.scss";
@import "./Colors.scss";
@import "./Device.scss";
@import "./Theme.scss";

* {
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 20px;
  background-color: transparent;
  background-clip: padding-box;
  padding-right: 10px;

  @include mobile-size{
    width: 0;
    padding-right: 0;
  }
}

*::-webkit-scrollbar-thumb {
  border-right: 6px transparent solid;
  border-left: 6px transparent solid;
  border-radius: 8px;
  background-clip: padding-box;
  background-color: rgba($color: #000, $alpha: 0.4);
}

body {
  margin: 0;
  font-family: "Modern Era";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  text-align: center;
  font-size: 70px;
  line-height: 80px;
  font-weight: 500;
  margin-bottom: 30px;

  @include mobile-size {
    font-size: 30px;
    margin-bottom: 16px;
  }
}

h3 {
  font-weight: 400;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 0.75px;
  text-align: left;
  margin-bottom: 30px;

  @include mobile-size {
    font-size: 20px;
    line-height: 32px;
  }
}

.h4 {
  font-weight: 500;
  font-size: 24px;
  line-height: 38px;
  text-align: center;
  letter-spacing: 1.2px;

  @include mobile-size {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 1px;
  }
}

input,
input:focus,
input:active,
input:hover {
  outline: none;
  box-shadow: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: black;
  box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

@include dark-mode {
  color: #fff;
  background-color: #010d08;

  *::-webkit-scrollbar-thumb {
    background-color: rgba($color: #fff, $alpha: 0.4);
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-text-fill-color: white;
  }
}
