@import "../../../Assets/Styles/Imports.scss";

.footerNavBarDesktop {
    width: 100%;
    background: $WhiteColor;
    display: flex;
    justify-content: space-between;
    height: 80px;
    margin-bottom: 30px;
    box-sizing: border-box;
    align-items: center;
    border-radius: 40px;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(48.9291px);
    max-width: 1599px;
    margin-left: 10%;
    margin-right: 10%;

    @include mobile-size{
        margin-right: 0;
        margin-left: 0;
        height: 50px;
    }
}

.buttonNextWrapper {
    display: inline-flex;
    align-items: center;
}

.buttonBack,
.linkNext {
    position: relative;
    text-decoration: none;
    font-weight: 500;
    font-size: 18px;
    line-height: 34px;
    text-align: center;
    letter-spacing: 0.5px;

    @include mobile-size{
        visibility: hidden;
    }
}

.linkNext {
    margin-right: 30px;
    margin-left: 30px;

    @include mobile-size{
        display: none;
    }
}

.buttonBack {
    margin-left: 71px;

    &:before {
        content: "";
        transform: rotate(180deg);
        position: absolute;
        top: 12px;
        left: -34px;
        height: 12px;
        width: 23px;
        background: url("../../../Assets/Images/Btn_right_arrow_desktop.svg");

        @include mobile-size{
            left: -57px;
            visibility: visible;
            display: block;
        }
    }
}

.buttonNext,
.buttonClose {
    position: relative;
    width: 80px;
    height: 80px;
    border-radius: 40px;
    margin-top: 0;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
    }
    @include mobile-size{
        width: 50px;
        height: 50px;
        border-radius: 25px;
    }
}

.buttonNext::after {
    background-image: url("../../../Assets/Images/Btn_right_arrow_desktop.svg");
}

.buttonClose::after{
    background-image: url("../../../Assets/Images/Close.svg");
}

@include dark-mode {
    .footerNavBarDesktop {
        background: #1f1f1f;
        color: $WhiteColor;
    }

    .buttonNext {
        background: #00a76d;
    }

    .buttonBack,
    .linkNext {
        color: $WhiteColor;

        &:hover {
            color: $WhiteColor;
        }
    }
}
