@import '../../../Assets/Styles/Imports.scss';

.main{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.content {
  padding-bottom: 0;
}

.textBlock {
  display: flex;
  justify-content: space-around;
  margin-bottom: 60px;

  @include mobile-size{
    display: block;
    padding-right: 20px;
    padding-left: 20px;
  }
}

.textBlock h1 {
  letter-spacing: 1.4px;
  display: inline-block;
  text-align: left;
  max-width: 700px;
}

.textBlock h3 {
  display: block;
  width: 600px;
  padding-top: 20px;

  @include mobile-size{
    width: 100%;
  }
}
