@import '../../../Assets/Styles/Imports.scss';

.main {
    padding: 116px 50px 0 50px;

    
    @include mobile-size{
        padding: 0 17px;
    }

}

.title {
    margin-bottom: 40px;
}

.subtitle {
    font-weight: 500;
    font-size: 25px;
    line-height: 38px;
    letter-spacing: 1.25px;
    margin-bottom: 34px;
}

.section {
    margin-bottom: 100px;

    @include mobile-size{
        margin-bottom: 60px;
    }
}

.deposits {
    display: flex;
    column-gap: 46px;
    row-gap: 46px;
    flex-wrap: wrap;
}

.sectionMessagesAndSubMenu {
    margin-bottom: 100px;
    display: flex;

    @include mobile-size {
        display: block;
        margin-bottom: 60px;
    }
}

.sectionMessages {
    margin-top: 38px;
    margin-left: 46px;
    margin-right: 46px;
    flex: 1;
    max-width: 600px;

    @include mobile-size{
        margin-bottom: 60px;
        margin-left: 0;
    }
}

.sectionSubMenu {
    flex: 1;
}

.productsList {
    display: flex;
    column-gap: 53px;
}
