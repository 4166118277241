@import "../../Assets/Styles/Imports.scss";


hr {
    margin-top: 40px;
    margin-bottom: 30px;
}

.mainSection {
    display: flex;
    justify-content: center;
    column-gap: 60px;
    flex-wrap: wrap;
}

.mainTitle {
    text-align: left;
    font-style: normal;
    font-weight: 500;
    font-size: 70px;
    line-height: 80px;
    letter-spacing: 1.4px;
    width: 100%;
    max-width: 770px;

    @include mobile-size {
        font-size: 36px;
        line-height: 46px;
        letter-spacing: 0.72px;
    }
}

.mainDescr {
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 0.75px;
    width: 100%;
    max-width: 770px;

    @include mobile-size {
        font-size: 22px;
        line-height: 32px;
        letter-spacing: 1.1px;
    }
}

.mainPopupButton {
    margin-top: 50px;
    display: flex;
    align-items: center;
    column-gap: 20px;
    text-decoration: none;
    color: $WhiteColor;

    @include mobile-size {
        margin-top: 30px;
    }

}

.taxCalculator {
    margin-top: 100px;
}

.splitSection {
    margin-top: 100px;
}

.splitTitleContainer {
    max-width: 770px;
    margin: 0 auto;
}

.splitTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 44px;
    line-height: 54px;
    letter-spacing: 1.1px;

    @include mobile-size {
        max-width: 310px;
    }
}

.splitDescription {
    margin-top: 40px;
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 34px;
    letter-spacing: 0.875px;
}

.splitCheckbox {
    margin-top: 40px;
}

.checkbox > * {
    justify-content: center;
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
    letter-spacing: 0.833333px;

    @include mobile-size {
        font-size: 18px;
        line-height: 30px;
        letter-spacing: 0.75px;
    }
}
