@import '../../../Assets/Styles/Imports.scss';

.container {
  margin: 115px 50px 0;
  
  @include mobile-size{
    margin: 0 17px;
  }
}

.deposits {
  display: flex;
  flex-direction: column;
  row-gap: 46px;

  @include mobile-size{
    margin-top: 30px;
  }
}

.productsContainer {
  margin-top: 120px;

  @include mobile-size{
    margin-top: 60px;
  }
}