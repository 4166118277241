@import '../../../Assets/Styles/Imports.scss';

.main {
  display: flex;
  flex-direction: column;
  margin-top: 270px;

  @include mobile-size {
    margin-top: 45px;
  }
}

.title {
  font-weight: 500;
  font-size: 44px;
  line-height: 54px;
  letter-spacing: 1.1px;
  color: $WhiteColor;

  @include mobile-size{
    font-size: 28px;
    line-height: 40px;
    letter-spacing: 0.7px;
    text-align: left;
  }
}

.description {
  font-weight: 400;
  font-size: 21px;
  line-height: 34px;
  letter-spacing: 0.875px;
  color: #FFFFFF;

  @include mobile-size {
    font-size: 18px;
  }
}

.label {
  font-weight: 500;
  font-size: 21px;
  margin-bottom: 2px;

  @include mobile-size {
    font-size: 18px;
  }
}

.labelOptional {
  font-size: 20px;
  margin-bottom: 2px;

  @include mobile-size {
    font-size: 18px;
  }
}

.switchBoxGroup {
  display: flex;

  @include mobile-size {
    flex-direction: column;
  }
}

.switch {
  margin-right: 60px;
  margin-top: 20px;
  margin-bottom: 20px;

  @include mobile-size {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 0;
  }
}

.timeRange {
  display: block;
  font-size: 14px;
  letter-spacing: 1px;
  color: #948E8D;
}

.block {
  margin-top: 30px;
}

.line {
  border-bottom: 1px solid #3D5463;
  padding-bottom: 30px;
}

.button {
  max-width: 227px;

  @include mobile-size {
    max-width: 292px;
    margin-top: 30px;
  }
}

@include dark-mode {
  .line {
    border-bottom: 1px solid #585656;
  }

  .labelOptional {
    color: #D5D2CD;
  }

  .switch > * {
    color: #90A3B0;

    @include mobile-size{
      margin-bottom: 0;
    }
  }
}
