@import "../../../../Assets/Styles/Imports.scss";

.main {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.topContainer {
    display: flex;
}

.titleSection h1 {
    text-align: left;

    @include mobile-size{
        font-weight: 500;
        font-size: 36px;
        line-height: 46px;
        letter-spacing: 0.72px;
    }
}

.titleSection h3 {
    @include mobile-size{
        font-weight: 400;
        font-size: 22px;
        line-height: 32px;
        letter-spacing: 1.1px;
    }
}

.content {
    width: 100%;
    max-width: 775px;
    margin: auto;
}

.content > h5 {
    text-align: center;
    font-size: 28px;
    font-weight: bold;
}

.content > p {
    font-weight: normal;
}

.content ul {
    padding-left: 0;
}

.content li {
    margin: 0;
    padding: 10px 0 10px 44px;
    list-style: none;
    background-image: url(../../../../Assets/Images/arrow-right.svg);
    background-repeat: no-repeat;
    background-position: left 15px;
    background-size: 20px;

    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.833333px;
    margin-bottom: 20px;

    @include mobile-size {
        font-size: 19px;
        line-height: 31px;
        letter-spacing: 0.791667px;
        background-position: left 15px;
        margin-bottom: 0;
    }
}

.infoTitle {
    margin-top: 100px;
    text-align: left;
    margin-right: auto;
    margin-left: 0;

    font-weight: 500;
    font-size: 44px;
    line-height: 54px;

    letter-spacing: 1.1px;

    @include mobile-size {
        margin-top: 44px;
        margin-bottom: 0;
        font-weight: 700;
        font-size: 28px;
        line-height: 40px;
        letter-spacing: 0.7px;
    }
}


.button {
    margin-top: auto;
    margin-bottom: 50px;
}

.p1 {
    margin-bottom: 46px;
    text-align: left;
    font-weight: bold;
    font-size: 20px;
    line-height: 34px;

    letter-spacing: 0.833333px;

    @include mobile-size {
        margin-bottom: 20px;

        font-weight: 400;
        font-size: 22px;
        line-height: 32px;
        letter-spacing: 1.1px;
    }
}

.p2 {
    text-align: left;
    font-size: 21px;
    line-height: 34px;

    letter-spacing: 0.875px;

    margin-bottom: 60px;

    @include mobile-size {
        font-size: 19px;
        line-height: 31px;
        letter-spacing: 0.791667px;
    }
}

.panel{
    @include mobile-size{
        margin-top: 60px;
    }
}