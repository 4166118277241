@import "../../../Assets/Styles/Imports.scss";
.content {
  padding: 78px 120px;

  @include mobile-size {
    padding: 25px 0;
    margin-right: -2px;
    padding-bottom: 50px;
  }
}

.content h4 {
  font-weight: bold;
  font-size: 44px;
  line-height: 44px;
  letter-spacing: 0.75px;
  margin-bottom: 40px;

  @include mobile-size {
    font-weight: 500;
    font-size: 22px;
    line-height: 36px;
    letter-spacing: 0.55px;
    margin-bottom: 18px;
  }
}

.content h5 {
  font-weight: normal;
  font-size: 21px;
  line-height: 34px;
  letter-spacing: 0.833333px;
  margin-bottom: 40px;

  @include mobile-size {
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.75px;
  }
}

.content h6 {
  position: relative;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;

  letter-spacing: 0.833333px;

  @include mobile-size {
    font-weight: 700;
    font-size: 18px;
  }

}

.content h6 > img {
  position: absolute;
  top: 50%;
  left: -40px;
  transform: translate(0, -50%);
  width: 20px;

}


.content p {
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  letter-spacing: 0.833333px;
  margin-bottom: 20px;

  @include mobile-size {
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.75px;
  }
}

.list {
  padding-left: 40px;

  @include mobile-size{
    margin-bottom: 258px;
  }
}

.list > li {
  position: relative;
  list-style-type: none;

  &::before {
    position: absolute;
    left: -40px;
    content: ' ';
    background-image: url("../../../Assets/Images/arrow-right.svg");
    background-size: 100%;
    height: 20px;
    width: 20px;
  }
}

.link {
  margin-top: 10px;
  font-weight: normal;
  font-size: 20px;
  line-height: 34px;
  letter-spacing: 0.833333px;

  @include mobile-size {
    font-size: 18px;
  }
}
