@import "../../../Assets/Styles/Imports.scss";

.profileContainer {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), rgba(15, 15, 15, 0.4);
    border: 1px solid #585656;
    backdrop-filter: blur(13.5914px);
    border-radius: 10px;
    max-height: 469px;
    width: 100%;
    max-width: 478px;
    text-align: center;

    .profileChatIcon{
        width: 100px;
        padding: 20px;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), rgba(15, 15, 15, 0.4);
        backdrop-filter: blur(48.9291px);
        border-radius: 25px;
        margin: 30px 0;
    }

    .button {
        display: block;
        margin: 30px auto 62px;
        max-width: 246px;
    }
}

.profileTitle {
    font-weight: 500;
    font-size: 25px;
    line-height: 38px;
    text-align: center;
    letter-spacing: 1.25px;
}