@import '../../../../Assets/Styles/Imports.scss';
.Step{
  max-width: 120px;
  width: 100%;
  padding: 8px 0;
  position: relative;
  box-sizing: content-box;
}

.StepHeader{
  position: relative;
  display: flex;
  width: 100%;
}

.Step.Selected{
  border-radius: 10px;
}

.stepProgress{
  width: 100%;
  min-height: 6px;
  margin: auto;
  background-color: #8F3484;

  @include mobile-size{
    min-height: 3px;
  }
}

.stepProgress > div{
  height: 6px;
  background-color: rgba(255, 255, 255, 0.3);

  @include mobile-size{
    height: 3px;
  }
}


@include dark-mode{
  .stepProgress{
    background-color: rgba(255, 255, 255, 0.3);
  }

  .stepProgress > div{
    background-color: #00A76D;
  }
  
}