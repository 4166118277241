@import '../../../Assets/Styles/Imports.scss';
.popup{
    display: flex;
    padding: 0;
    transform: translate(100px, 0) !important;
    transition: transform 0.2s ease-in;
    @include mobile-size{
        transform: translate(0, 100px) !important;
    }
}
.popup > div{
    height: 100%;
    min-height: 100%;
    @include mobile-size{
        margin-left: unset;
        min-width: unset;
    }
}
:global(.modal.show) .popup{
    transform: none !important;
}
.main {
    max-width: 1485px;
    @include mobile-size {
        max-width: unset;
    }
}


.splitView {
    column-gap: 60px;
    
    @include mobile-size {
        display: block;
    }
}

.titleSection {
    flex-shrink: 0;
    max-width: 700px;
}

.amount {
    font-style: normal;
    font-weight: 500;
    font-size: 44px;
    line-height: 54px;
    letter-spacing: 1.1px;
    color: $WhiteColor;
    margin-top: 30px;
    margin-bottom: 20px;
}

.contentSection p {
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 34px;
    letter-spacing: 0.875px;
    color: $WhiteColor;
    margin-bottom: 40px;
}

.amountTitle {
    font-weight: 500;
    font-size: 25px;
    line-height: 38px;
    letter-spacing: 1.25px;
    color: $WhiteColor;
}

.amountSubtitle {
    font-weight: 400;
    font-size: 21px;
    line-height: 34px;
    letter-spacing: 0.875px;
}

.line {
    border: 1px solid #FFFFFF;
    margin: 30px 0;
}

.popupContainer {
    padding-top: 180px;
    max-width: 777px;
    margin-left: 280px;
}
.infoPopupTitle {
    font-weight: 500;
    font-size: 44px;
    line-height: 54px;
    letter-spacing: 1.1px;
}

.infoPopupTable {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin-top: 60px;
}

.infoPopupTableRow {
    border-bottom: 1px solid #ffffff50;

}
.infoPopupTableData {
    height: 48px;
}
.infoPopupTableRow > .infoPopupTableData:last-child {
    text-align: right;
}

.infoPopupCloseLink {
    display: block;
    margin-top: 60px;
}