@import '../../../../Assets/Styles/Imports.scss';

.main {
    display: flex;
    justify-content: flex-start;
    column-gap: 200px;
    flex-wrap: wrap;
}

.titleContainer {
    width: 100%;
    max-width: 635px;
}

.answerContainer {
    max-width: 635px;
}

.titleContainer > h1 {
    text-align: left;
}

