@import "../../../../Assets/Styles/Imports.scss";

.main {
  display: flex;
  justify-content: center;
  column-gap: 20px;

  @include mobile-size{
    display: block;
  }
}

.titleSection {
  max-width: 630px;
  margin-right: 50px;

  @include mobile-size{
    margin-right: 0;
  }
}

.title {
  text-align: left;
  margin-bottom: 40px;
  letter-spacing: 1.4px;

  @include mobile-size {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    letter-spacing: 0.72px;
    margin-bottom: 16px;
  }
}

.secondaryText {
  color: $SecondaryTextColor;
  text-align: left;
  font-weight: normal;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 0.75px;
  max-width: 100%;
  margin-left: 0;
  margin-bottom: 40px;

  @include mobile-size {
    font-weight: 400;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: 1.1px;
  }
}

.footerText {
  text-align: left;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.5px;
  max-width: 450px;

  @include mobile-size {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.5px;
  }
}

.productContainer {
  margin-top: 110px;
}

@include dark-mode {
  .secondaryText {
    color: $WhiteColor;
  }
}
