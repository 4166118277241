@import '../../../Assets/Styles/Imports.scss';

.main {
  background: #F1F4FA;
  border: 1px solid #3D5463;
  border-radius: 2px;
  color: #90A3B0;
  font-size: 16px;
  padding-top: 7px;
  padding-left: 15px;
  padding-right: 40px;
  padding-bottom: 8px;
  width: 100%;
  min-height: 200px;
}

@include dark-mode {
  .main {
    background: linear-gradient(39.11deg, #0F0F0F 0.62%, #1F1F1F 101.06%, #252525 101.06%);
    border: 1px solid white;
  }
}
