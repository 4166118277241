@import "../../../Assets/Styles/Imports.scss";

.detailsRiskInfo {
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 0.75px;
  margin-bottom: 60px;

  @include mobile-size{
    margin-left: 15px;
    margin-right: 15px;

    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0.6px;
  }
}

.investmentClasses {
  font-weight: 500;
  font-size: 44px;
  line-height: 54px;

  letter-spacing: 1.1px;

  @include mobile-size{
    margin-left: 15px;
    margin-right: 15px;

    font-size: 28px;
    line-height: 40px;
    letter-spacing: 0.7px;
  }
}

.description {
  margin: 20px 0 0;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 34px;
  letter-spacing: 0.875px;
}

.lineSeparator {
  margin: 10px 0;
  border: 2px solid white;
}

.chartSection{
  display: flex;
  margin: 90px 0;
  align-items: center;
  column-gap: 80px;

  @include mobile-size {
    display: block;
    margin: 0;
  }
}

.chartContainer{
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 400px;
}

.chartLegend{
  display: flex;
  align-items: center;
  line-height: 26px;
  margin-bottom: 20px;

  @include mobile-size{
    margin-left: 40px;

    &:first-child{
      margin-top: 40px;
    }

    &:last-child{
      margin-bottom: 40px;  
    }
  }
}
.assetClassColor{
  display: inline-block;
  min-width: 20px;
  min-height: 20px;
  margin-right: 20px;
  border-radius: 50%;
}

.assetClassDiv {
  padding-bottom: 60px;
}

.detailsPercentRecordDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 20px 0 25px;
  border-bottom: 1px solid #ffffff50;

  &:first-child {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), rgba(15, 15, 15, 0.4);
    backdrop-filter: blur(48.9291px);
    border-bottom: 1px solid white;
  }

  @include mobile-size{
    height: 100%;
  }
}

.detailsPercentRecordDiv > *:last-child {
  margin-left: auto;
}

.detailsAssetClassName,
.detailsPercentRecordDivName {
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  margin: 0;

  @include mobile-size{
    padding-right: 15px;
  }
}

.detailsAssetClassPercentage {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.5px;
  margin: 0;
}

.detailsPercentRecordDivName {
  font-weight: 400;
}

/* DISCLAIMER STYLES */

.strategyDetailsTitle,
.disclaimerTitle {
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 0.75px;
  border-bottom: 1px solid $WhiteColor;
  padding-bottom: 8px;
  margin-bottom: 40px;

  @include mobile-size{
    padding-left: 15px;
    padding-right: 15px;
  }
}

.detailsInfoText {
  font-weight: normal;
  font-size: 21px;
  line-height: 34px;
  letter-spacing: 0.875px;
  margin-bottom: 60px;

  @include mobile-size{
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* STRATEGY DETAILS STYLES */

.strategyDetailsContainer {
  margin-top: 100px;
  margin-bottom: 120px;

  @include mobile-size{
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.strategyDetailsColumn {
  display: flex;
  align-items: center;
  column-gap: 60px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #FFFFFF50;
  min-height: 50px;

  &:first-of-type {
    padding-top: 10px;
    border-top: 1px solid #FFFFFF50;
  }

  @include mobile-size{
    display: block;
  }
}

.strategyDetailsParam {
  align-self: baseline;
  width: 100%;
  max-width: 217px;

  font-weight: 700;
  font-size: 19px;
  line-height: 32px;
  letter-spacing: 0.8px;
}

.strategyDetailsDescription {
  align-self: baseline;
  font-weight: 400;
  font-size: 19px;
  line-height: 32px;
  letter-spacing: 0.8px;
}