@import "../../../Assets/Styles/Imports.scss";

.tabsSelectorContainer{
    display: inline-flex;
    overflow: hidden;
    margin: auto;
    max-width: 100%;
    width: 100%;
    column-gap: 8%;
    padding: 0 16px;

    @include mobile-size{
        column-gap: 5%;
    } 
}

.tabsSelectorContainer > *:first-child{
    margin-left: auto;
}

.tabsSelectorContainer > *:last-child{
    margin-right: auto;
}


.tabsContainer{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.tabContent{
    position: relative;
    flex: 1;
}

.tabsSelectorContainer.center{
    justify-content: center;
}