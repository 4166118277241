.page {
  background: linear-gradient(39deg, #0F0F0F62, #1F1F1F), url(../../../Assets/Images/background-green-wave.png);
  background-blend-mode: darken;
  background-repeat:no-repeat;
  background-position: center top;
  background-size: 100%;


}

.main {
  display: flex;
  flex-direction: column;
  row-gap: 60px;
  justify-content: center;
  @extend .container
}

.header {
  height: 155px;
  background-color: #1B1B1B;
  @extend .full-width
}

.logoImg {
  margin-top: 50px;
  width: 133px;

}

.title {
  margin-top: 20px;
  margin-bottom: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 44px;
  line-height: 54px;
  letter-spacing: 1.1px;
  text-align: left;
}

.description {
  font-weight: 400;
  font-size: 21px;
  line-height: 34px;
  letter-spacing: 0.875px;
  margin: 0;
}

.subtitle {
  margin-bottom: 40px;
  font-weight: 400;
  font-size: 25px;
  line-height: 38px;
  letter-spacing: 1.25px;
}

.dashboardButton {
  margin: 0;
  max-width: 210px;
}

.section {
  margin-top: 100px;
}

.bestRegards {
  @extend .section;
  @extend .description;
}

.helpcenterButton {
  max-width: 220px;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 112px;
  background: linear-gradient(0deg, #0F0F0F, #0F0F0F), rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(13.5914px);

  font-weight: 400;
  font-size: 21px;
  line-height: 34px;
  letter-spacing: 0.875px;

  @extend .full-width

}

.container {
  width: 100%;
  max-width: 775px;
  margin: 0 auto;
}

.full-width {
  position: relative;
  right: 50%;
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100vw;
  width: 100vw;
}