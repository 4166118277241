.main {
  display: flex;
  justify-content: center;
  column-gap: 60px;
  flex-wrap: wrap;

}

.title {
  text-align: left;
  letter-spacing: 1.4px;
}

.description {
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 0.75px;
}

.titleContainer {
  max-width: 770px;
}

.panel {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-self: flex-end;

  width: 100%;
  max-width: 770px;
  height: 360px;
  padding: 20px;
  margin-top: 65px;
}

.form {
  width: 100%;
  max-width: 500px;
  margin: 19px 0;
}

.label {
  margin-bottom: 10px;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 0.75px;
}

.input {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid white;
  height: 70px;
  padding: 0;
  font-weight: 400;
  font-size: 44px;
  line-height: 70px;
  letter-spacing: 1.1px;
  color: white;

  &::placeholder {
    display: block;
    margin-bottom: 30px;
    color: #585656;
  }
}

.input:hover,
.input:focus,
.input:active {
  box-shadow: unset;
  background-color: transparent;
  border-bottom: 1px solid white;
  height: 70px;
  padding: 0;
  font-size: 44px;
  color: white;
}

.resendCodeLink {
  display: block;
  margin-top: 20px;
  color: #D5D2CD;
}
