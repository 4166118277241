.main{
    color: #90A3B0;
    text-decoration: underline;
    cursor: pointer;
}

.disabled {
  cursor: none;
  pointer-events: none;
}
