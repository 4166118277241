.container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
}

.scrollArea {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 100%;
    height: 100%;
    max-height: 100%;
    overflow-y: overlay;
    overflow-x: hidden;
}