@import '../../../Assets/Styles/Imports.scss';

.amount {
  font-weight: 400;
  font-size: 70px;
  line-height: 80px;
  letter-spacing: 1.4px;

  @include mobile-size{
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    letter-spacing: 0.72px;
  }
}

.amountCurrency {
  margin-left: 18px;
  font-weight: 400;
  font-size: 21px;
  line-height: 34px;
  letter-spacing: 0.875px;
}

.profitFigures {
  display: flex;
  justify-content: center;
  max-width: 350px;
  height: 46px;
  padding: 6px 20px 6px 15px;
  margin-top: 25px;

  background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), rgba(15, 15, 15, 0.4);
  border: 1px solid #585656;
  backdrop-filter: blur(13.5914px);
  border-radius: 23px;

  font-weight: 400;
  font-size: 19px;
  line-height: 32px;
  letter-spacing: 0.8px;
  color: $ActionColor;

  img {
    margin-right: 8px;
  }
}

.stockDown {
  color: $ErrorColor;
  img {
    transform: rotate(180deg);
  }
}

.amountChart {
  margin-top: 26px;
  font-weight: 500;
  font-size: 44px;
  line-height: 54px;
  letter-spacing: 1.1px;

  @include mobile-size{
    font-size: 28px;
    line-height: 40px;
    letter-spacing: 0.5px;
    margin: 0 29px 0 29px;
  }
}

.amountCurrencyChart {
  margin-left: 11px;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.5px;
}

.profitFiguresChart {
  height: 28px;
  padding: 0;
  margin-top: 5px;

  background-color: transparent;
  border-radius: 0;
  border: 0;
  backdrop-filter: none;

  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.5px;

  color: $ActionColor;

  img {
    margin-right: 8px;
  }

  @include mobile-size{
    margin: 0 29px 0 29px;
  }
}