@import "../../../Assets/Styles/Imports.scss";

.main {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    overflow: visible;
}

.contentContainer {
    flex: 1 1;
    display: flex;
    flex-direction: column;
}

.navBarContainer {
    min-width: 100%;
    position: relative;
    z-index: 1500;
}

.navBarContainerPaddings {
  padding-top: 40px;
  padding-left: 55px;
  padding-right: 55px;

  @include mobile-size{
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
  }
}

.footerContainer{
    margin-top: auto;
    display: flex;
    justify-content: center;
    width: 100%;
}
