@import '../../../../Assets/Styles/Imports.scss';

.selector {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  border: unset!important;
  font-size: 18px;
  height: 40px;
  width: unset;
  cursor: pointer;
}

.arrowContainer {
  padding-left: 10px;

  img {
    width: 15px;
  }
}

// dropdown
.langSelect > div {
  padding: 0;
  font-size: 21px;
  top: 10px!important;
  left: -17px!important;
  min-width: 82px;
}
