
@font-face {
    font-family: "Modern Era";
    font-weight: normal;
    src: local("Modern Era"), url(../Fonts/ModernEra/ModernEra-Regular.otf) format("opentype");
}



@font-face {
    font-family: "Modern Era";
    font-weight: 500;
    src: local("Modern Era"), url(../Fonts/ModernEra/ModernEra-Medium.otf) format("opentype");
}


@font-face {
    font-family: "Modern Era";
    font-weight: bold;
    src: local("Modern Era"), url(../Fonts/ModernEra/ModernEra-Bold.otf) format("opentype");
}


@font-face {
    font-family: "CeraPro";
    font-weight: normal;
    src: local("CeraPro"), url(../Fonts/CeraPro/Cera_Pro_Regular.otf) format("opentype");
}

@font-face {
    font-family: "CeraPro";
    font-weight: bold;
    src: local("CeraPro"), url(../Fonts/CeraPro/Cera_Pro_Bold.otf) format("opentype");
}

@font-face {
    font-family: "CeraPro";
    font-weight: 900;
    src: local("CeraPro"), url(../Fonts/CeraPro/Cera_Pro_Black.otf) format("opentype");
}

@font-face {
    font-family: "CeraPro";
    font-weight: 300;
    src: local("CeraPro"), url(../Fonts/CeraPro/Cera_Pro_Light.otf) format("opentype");
}

@font-face {
    font-family: "CeraPro";
    font-weight: 100;
    src: local("CeraPro"), url(../Fonts/CeraPro/Cera_Pro_Thin.otf) format("opentype");
}

@font-face {
    font-family: "CeraPro";
    font-weight: 500;
    src: local("CeraPro"), url(../Fonts/CeraPro/Cera_Pro_Medium.otf) format("opentype");
}
