@import "../../../Assets/Styles/Imports.scss";

.error_bg {
    background: $WhiteColor;
    padding: 12px;
    border-left: 10px solid $ErrorColor;
    position: relative;

    input {
        color: #000!important;
    }

    span {
        top: 11px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: $ErrorColor!important;
    }

    .error_tip {
        position: absolute;
        background: $ErrorColor;
        border-radius: 4px;
        padding: 9px;
        color: $WhiteColor;
        top: -40px;
        right: 20px;

        &:after {
            position: absolute;
            content: '';
            border: 10px solid transparent;
            top: 42px;
            right: 12px;
            border-top: 10px solid $ErrorColor;
        }

    }

    &:after {
        position: absolute;
        content: '';
        right: 20px;
        top: 20px;
        height: 50px;
        width: 40px;
        background-size: cover;
        background: url('../../../Assets/Images/Feedback_error.svg') center no-repeat;
    }

}

.main,
.main:hover,
.main:focus,
.main:active {
    @include Field;
    height: 45px;

    outline: none;
    box-shadow: none;

    border-radius: 0;

    @include mobile-size{
        @include Field-mobile;
    }
}

.main[type=number] {
    appearance: textfield;
    -moz-appearance:textfield;
}
.main::-webkit-inner-spin-button {
    appearance: none;
    -webkit-appearance: none;
    margin: 0
}

.label{
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    
    @include mobile-size{
        margin-bottom: 30px;
    }
}

.label > span,
.pass > span{

    color: #948E8D;
    font-size: 14px;
    transition: all 0.3s;
    transition-property: opacity, transform, color;
    margin-right: auto;
}

.main:placeholder-shown + span,
.pass > input:placeholder-shown + span {
    opacity: 0;
    color: white;
    transform: translate(20px, 30px) scale(1.3);
}


.pass {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;

    @include Field;
    padding: unset;
    border: unset;
    position: relative;

    border-radius: 0;

}

.pass > input {
    @include Field;
    height: 100%;
    width: 100%;
    padding-right: 40px;
    letter-spacing: 3px;


    @include mobile-size{
        @include Field-mobile;
    }
}

.pass > input[type='text'],
.pass > input:placeholder-shown {
    letter-spacing: unset;
}

.pass > input:hover,
.pass > input:focus,
.pass > input:active {

    outline: none;
    box-shadow: none;

    background-color: transparent;
}


.pass > div,
.pass > div:hover,
.pass > div:focus,
.pass > div:active {
    display: inline-table;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 12px;
    margin: auto 0;
    border: unset;
    box-shadow: unset !important;
}

.pin {
    display: flex;
    column-gap: 10px;
    margin-top: 12px;
}

.pin input,
.pin input:hover,
.pin input:focus,
.pin input:active  {
    width: 100%;
    text-align: center;
}

@include dark-mode {
    .main {
        @include Field-dark;
    }

    .main::placeholder {
        color: #d5d2cd;
    }

    .pass {
        @include Field-dark;
    }

    .pass > input{
        color: white;
    }

    .pass > input::placeholder {
        color: #d5d2cd;
    }
}
