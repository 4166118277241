@import "../../../Assets/Styles/Imports.scss";

.main {
  display: flex;
  background: linear-gradient(0deg, rgba(31, 31, 31, 0.5), rgba(31, 31, 31, 0.5)), url(../../../Assets/Images/Risk/risk-profile-two.jpg);
  background-blend-mode: multiply;
  background-repeat:no-repeat;
  background-size: 180% 180%;
  background-position: center top;
  min-height: 100%;

  @include mobile-size{
    display: block;
  }
}

.sidebar {
  width: 274px;
  min-width: 274px;

  background: linear-gradient(0deg, rgba(15, 15, 15, 0.6), rgba(15, 15, 15, 0.6)), rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(13.5914px);
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  overflow: hidden;

  @include mobile-size{
    display: none;
  }
}

.logo {
    max-width: 180px;
    height: 76px;
    margin-bottom: 50px;
    z-index: 1;
    padding-top: 50px;
    padding-left: 50px;

    @include mobile-size {
      margin-bottom: 22px;
      padding-top: 17px;
      padding-bottom: 17px;
      padding-left: 17px;
      max-width: 130px;
    }
}

.menu {
  display: flex;
  flex-direction: column;
  padding-left: 50px;
  padding-right: 20px;
  height: 100%;
  overflow: auto;
}

.menuFooter{
  height: 204px;
  overflow: visible;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden overlay;
  min-height: 100%;
}

.contentContainer{
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  min-height: 100%;
}

.content {
  flex: 1;
}

.newPortfolioBlock {
  height: 112px;
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid $DarkGrayLine;
}

.footer {
  margin-top: 80px;
  height: 112px;
  background: linear-gradient(0deg, #0F0F0F, #0F0F0F), rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(13.5914px);
  padding: 39px 0 39px 49px;
  display: flex;
  align-items: center;
  color:#D5D2CD;

  @include mobile-size{
    margin-top: 30px;
    padding: 30px 0 18px 30px;
  }
}

.buttonAddPortfolio {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-top: 0;
    margin-right: 16px;

    &::after {
        content: "";
        position: absolute;
        top: 12px;
        left: 12px;
        height: 16px;
        width: 16px;
        background: url("../../../Assets/Images/plus.svg");
    }
}

.linkAddPortfolio{
  color: $WhiteColor;
  text-decoration: none;

  &:hover{
    color: $WhiteColor;
  }
}

.mobileMenu{
  display: none;

  @include mobile-size{
    display: flex;
    justify-content: space-between;
    align-items: top;
  }
}