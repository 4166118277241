@import '../../../../Assets/Styles/Imports.scss';

.splitView {
  column-gap: 15%;
}

.titleContainer {
  max-width: 600px;
}

.titleContainer h1 {
  text-align: left;
}

.selectors {
  padding-top: 150px;
}