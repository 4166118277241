@import '../../../Assets/Styles/Imports.scss';

.main {
  display: flex;
  flex-direction: column;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 60px;
  padding-bottom: 70px;

  @include mobile-size {
    margin-top: 40px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 40px;
    padding-bottom: 35px;
  }
}

.mainBlock {
  max-width: 770px;
  margin: 0 auto 110px auto;
}

.title {
  font-weight: 500;
  font-size: 44px;
  line-height: 54px;
  letter-spacing: 1.1px;
  text-align: left;
  margin-bottom: 40px;
  color: $WhiteColor;

  @include mobile-size {
    font-size: 40px;
  }
}

.description {
  font-weight: 400;
  font-size: 25px;
  line-height: 38px;
  letter-spacing: 1.25px;
  margin-bottom: 60px;
  color: $WhiteColor;

  @include mobile-size {
    font-size: 20px;
    margin-bottom: 30px;
  }
}

.advantages {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: -477px;

  @include mobile-size {
    margin-bottom: 0;
  }
}

.advantage {
  border-left: 1px solid #979797;
  padding-left: 63px;
  padding-bottom: 30px;
  max-width: 630px;

  @include mobile-size {
    position: inherit !important;
    padding-left: 0;
    padding-top: 50px;
    padding-bottom: 50px;
    border-top: 1px solid #979797;
    border-left: none;
  }
}

.advantageOne {
  position: relative;
  left: -350px;
}

.advantageTwo {
  position: relative;
  right: -350px;
  top: -250px;
}

.advantageThree {
  position: relative;
  left: -200px;
  top: -300px;
}

.advantageNumber {
  font-weight: 500;
  font-size: 200px;
  line-height: 263px;
  letter-spacing: 4px;
  color: #00A76D;

  @include mobile-size{
    font-size: 100px;
    line-height: 100px;
    letter-spacing: 2px;
    margin-left: 20px;
  }
}

.advantageTitle {
  font-weight: 500;
  font-size: 30px;
  line-height: 42px;
  letter-spacing: 0.75px;
  color: #FFFFFF;

  @include mobile-size {
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0.6px;
    margin-top: 20px;
  }
}

.advantageDescription {
  font-weight: 400;
  font-size: 21px;
  line-height: 34px;
  letter-spacing: 0.875px;
  color: #FFFFFF;

  @include mobile-size {
    font-size: 19px;
    line-height: 31px;
    letter-spacing: 0.791667px;
  }
}

.button {
  width: 334px;
}
