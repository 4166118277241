@import "../../../Assets/Styles/Imports.scss";

.splitView {
  column-gap: 60px;

  @include mobile-size {
    display: block;
  }
}

.titleSection {
  max-width: 700px;
}

.contentSection {
  width: 100%;
  max-width: 770px;
  min-width: 300px;
}

.mainTitleContainer {
  padding: 40px 0;
}

.mainTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  line-height: 62px;

  @include mobile-size {
    font-size: 30px;
  }
}

.warningImage {
  width: 60px;
  margin-bottom: 20px;

  @include mobile-size {
    width: 40px;
  }
}

.warningMain {
  padding: 0 55px;

  @include mobile-size {
    padding: unset;
  }
}

.main {
  display: flex;
  flex-direction: column;
  row-gap: 40px;

  padding: 60px;

  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.9px;
  color: $BlackColor;
  text-align: center;

  border: 1px solid #585656;
  backdrop-filter: blur(13.5914px);
  border-radius: 20px;
}

.paymentTitle {
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 0.75px;
}

.paymentSubtitle {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1.25px;
}

.main > * {
  text-align: left;
}


.title {
  line-height: 62px;
  text-align: left;
  margin-bottom: 60px;

  @include mobile-size {
    margin-bottom: 20px;
    line-height: 42px;
    font-size: 30px;
  }
}

.paragraph {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 34px;
}

.subtitle {
  margin-bottom: 20px;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.65px;

  @include mobile-size {
    font-size: 20px;
  }
}

.amountText {
  font-weight: 500;
  font-size: 44px;
  line-height: 54px;
  /* identical to box height, or 123% */

  letter-spacing: 1.1px;
  padding: 10px 0;
}

.amountInfo {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.5px;
  max-width: 500px;
}

.qrcode {
  margin-top: 20px;
  width: 180px;
  margin-bottom: 20px;
}

.ghostButton {
  max-width: 240px;
  text-align: center;
  margin-top: 0;
}

.alertTitle {
  margin-top: 60px;
}

@include dark-mode {
  .title,
  .subtitle,
  .mainTitleContainer {
    color: #fff;
  }

  .main {
    background: linear-gradient(
                    0deg,
                    rgba(255, 255, 255, 0.05),
                    rgba(255, 255, 255, 0.05)),
    rgba(15, 15, 15, 0.4);
    color: #fff;
  }

  .grayBlock {
    color: $BlackColor;
  }
}
