@import "../../../Assets/Styles/Imports.scss";

.main {
    border: 1px solid #585656;
    box-sizing: border-box;
    border-radius: 20px;
    padding: 20px;
    background-color: black;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), rgba(20, 20, 20, 0.2);
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(13.5914px);

    @include mobile-size{
        margin-bottom: 20px;
    }
}

.selected {
    background: linear-gradient(0deg, rgba(15, 15, 15, 0.5), rgba(15, 15, 15, 0.5)), rgba(255, 255, 255, 0.05);
}

