@import '../../../../Assets/Styles/Imports.scss';

.popup{
  display: flex;
  align-items: flex-end;
  justify-content: right;
  margin: unset;
  margin-left: auto;
  height: 100%;
  max-width: 654px;
  padding: 0;
  transform: translate(100px, 0) !important;
  transition: transform 0.2s ease-in;

  @include mobile-size{
    transform: translate(0, 100px) !important;
  }
}

.popup > div{
  height: 100%;
  width: 100%;
  max-width: 654px;
  max-height: unset;
  @include mobile-size{
    margin-left: 0;
  }
}

:global(.modal.show) .popup{
  transform: none !important;
}

.main{
  position: relative;
  color: black;
  height: 100%;
  padding: 25px 20px 25px 45px;
  margin-right: 0 !important;
  background: #292828;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(89.7033px);

  @include mobile-size {
    padding-left: 20px;
  }

}

.actionBar{
  display: flex;
  justify-content: space-between;
}

.actionBar > img{
  cursor: pointer;
  width: 40px;
  @include mobile-size{
    width: unset;
  }
}

.navList {
  list-style-type: none;
  max-width: 440px;
  padding-left: unset;
  margin: 240px auto 0;

  @include mobile-size {
    margin: unset;
    margin-top: 200px;
  }
}

.navItem {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 0.75px;
  text-transform: none;
  margin: 40px 0;

  @include mobile-size {
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0.6px;
  }

}

.navItem > span {
  color: white;
  text-decoration: none;

  &:hover {
    color: $ActionColor;
  }
}

.burgerButton {
  position: relative;
  width: 120px;
  height: 40px;
  cursor: pointer;

  &::before, &::after {
    position: absolute;
    content: " ";
    top: 10px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 60px;
    height: 1px;
    background: black;
  }

  &:after {
    top: unset;
    bottom: 10px;
  }

  @include mobile-size {
    max-width: 50px;
    max-height: 16px;

    &::before, &::after {
      width: 50px;
    }

    &::after{
      top: 0;
    }

    &:before {
      top: 16px;
    }
  }
}


@include dark-mode {
  .main {
    color: $WhiteColor;
  }
  .burgerButton {
    &::before, &::after {
      background: white;
    }
  }
}
