@import "../../../Assets/Styles/Imports.scss";

.container {
  display: flex;
  column-gap: 50px;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1297px;
  margin: 170px auto 0;
}


.title {
  text-align: left;
  margin: 0;
}

.helpcenter {
  margin-top: 80px;
}

.leftSideContainer {
  width: 100%;
  max-width: 610px;
}
.rightSideContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 610px;
  row-gap: 40px;
  padding-top: 20px;
}

.riskTitle {
  font-weight: 400;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 0.75px;
  max-width: 610px;
}

.riskDescription {
  font-weight: 400;
  font-size: 21px;
  line-height: 34px;
  letter-spacing: 0.875px;
}