@import '../../../Assets/Styles/Imports.scss';

.datepickerContainer {
  position: relative;
  width: 100%;
  max-width: 355px;

  @include mobile-size {
    width: 100%;
    max-width: unset;
  }
}

.main {
  background: transparent;
  border: unset;
  border-bottom: 1px solid #3D5463;
  color: #90A3B0;
  font-size: 21px;
  font-weight: 400;
  padding: 16px 16px 16px 0;
  width: 100%;
  &:focus {
    border-bottom: 1px solid #3D5463;
  }
}

.iconContainer {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 30px;
  height: 30px;
}

.iconContainer > img {
  width: 100%;
}

@include dark-mode{
  .main {
    border-bottom: 1px solid white;
    color:  white;
  }

  .main::placeholder{
    color:#D5D2CD;
  }
}
