@import '../../../Assets/Styles/Imports.scss';

.main a {
    display: flex;
    align-items: center;
    color: $WhiteColor;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.5px;
    text-decoration: none;
    margin-bottom: 19px;
}

.main hr {
  color: #585656;
  margin-top: 30px;
  margin-bottom: 30px;
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 11px;
}

.activeLink {
  color: $ActionColor;
}

.main {
  @include mobile-size{
    margin-left: 50px;
  }
}
