@import "../../../Assets/Styles/Imports.scss";

.total,
.totalAmount
{
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 38px;
  letter-spacing: 1.25px;

}

.total {
  margin-top: -50px;
}
.totalAmount {
  margin-bottom: -50px;
}

.subtitle {
  margin-top: -50px;
  margin-bottom: 20px;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.65px;

  @include mobile-size {
    font-size: 20px;
  }
}
.amountText {
  font-weight: 500;
  font-size: 44px;
  line-height: 54px;
  /* identical to box height, or 123% */

  letter-spacing: 1.1px;
  padding: 10px 0;
}
.amountInfo {
  margin-bottom: -50px;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.5px;
  max-width: 500px;
}