@import '../../../Assets/Styles/Imports.scss';

.panel {
    border-radius: 0;
    padding: 40px;
    align-items: flex-end;
    max-width: 590px;
}

.main{
    height: 100%;
    padding-bottom: 20px;
}

.circleContainer{
    // padding-left: 10px;
    position: relative;
    width: 200px;
    height: 200px;
    margin: 0 auto;
    overflow: hidden;
}

.details {
    display: flex;
    height: 100%;
    align-items: flex-end;
    column-gap: 10px;
    row-gap: 30px;
    padding-bottom: 35px;

    @include mobile-size{
        flex-direction: column-reverse;
    }
}

.info{
    width: 220px;
    min-width: 220px;
    margin-right: auto;
}

.nameLabel {
    white-space: nowrap;
    font-weight: 700;
    font-size: 20px;
    line-height: 34px;
    letter-spacing: 0.833333px;
}

.valueLabel {
    white-space: nowrap;
    font-weight: 400;
    font-size: 30px;
    line-height: 44px;
    letter-spacing: 0.75px;
}

.input {
    background-color: transparent;
    border: none;
    height: 44px;
    color: white;
    white-space: nowrap;
    font-weight: 400;
    font-size: 30px;
    letter-spacing: 0.75px;
}

.disabled {
    pointer-events: none;
}

.disabled > .main {
    opacity: 0.4;
}
