@import '../../../Assets/Styles/Imports.scss';

.main{
    display: flex;
    flex-direction: column;
    min-height: 100%;
    width: 100%;
}

.loaderContainer{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    // background-color: rgba($color: #000000, $alpha: 0.78);
    backdrop-filter: blur(89.7033px);
    background-color: rgba(20, 20, 20, 0.3);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}


.loading *{
    overflow: hidden;
}