@import '../../../Assets/Styles/Imports.scss';

.flex {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

hr {
    margin: 80px 0;

    @include mobile-size{
        margin: 60px 0;
    }
}

.input {
    width: 100%;
    height: 44px;

    &:hover, &:focus {
        height: 44px;
    }
}

.inputContainer {
    width: 50%;
}