@import "../../../../Assets/Styles/Imports.scss";

.splitView {
    column-gap: 60px;

    @include mobile-size {
        display: block;
    }
}

.titleSection {
    max-width: 700px;
}

.contentSection {
    width: 100%;
    max-width: 770px;
    min-width: 300px;
}

.mainTitleContainer {
    padding: 40px 0;
}

.mainTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 50px;
    line-height: 62px;

    @include mobile-size {
        font-size: 30px;
    }
}

.warningImage {
    width: 60px;
    margin-bottom: 20px;

    @include mobile-size {
        width: 40px;
    }
}

.warningMain {
    padding: 0 55px;

    @include mobile-size {
        padding: unset;
    }
}

.main {
    margin: auto;
    padding: 60px;
    font-family: "CeraPro";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.9px;
    color: $BlackColor;
    text-align: center;
    p {
        font-size: 21px;
    }

    h5 {
        font-weight: 700;
        font-size: 30px;
        line-height: 40px;
        letter-spacing: 0.75px;
    }

    > *{
        text-align: left;
    }

    @include mobile-size {
        padding: 30px 15px;
    }
}

.title {
    font-style: normal;
    font-weight: 500;
    font-size: 50px;
    line-height: 62px;
    text-align: left;
    margin-bottom: 60px;

    @include mobile-size {
        margin-bottom: 20px;
        line-height: 42px;
        font-size: 30px;
    }
}

.paragraph {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 34px;
}

.subtitle {
    margin-bottom: 20px;
    font-family: "CeraPro";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.65px;
    color: $PrimaryTextColor;

    @include mobile-size {
        font-size: 20px;
    }
}

.qrcode {
    width: 200px;
    margin-top: 30px;
}

.alertTitle {
    margin-top: 60px;
}

@include dark-mode {
    .title,
    .subtitle,
    .mainTitleContainer {
        color: #fff;
    }

    .main {
        background: linear-gradient(
                360deg,
                rgba(51, 51, 53, 0.3) -73.73%,
                rgba(26, 26, 26, 0.3) 101.17%,
                rgba(37, 37, 37, 0.3) 101.17%
            ),
            #292828;
        color: #fff;
    }

    .grayBlock {
        color: $BlackColor;
    }
}
