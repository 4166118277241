
@import "../../../Assets/Styles/Imports.scss";

.main {
    padding: 30px 43px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), rgba(15, 15, 15, 0.3);
    border: 1px solid #585656;
    backdrop-filter: blur(13.5914px);
    border-radius: 10px;
}

.title {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 0.75px;
    color: $WhiteColor;
    padding-bottom: 40px;
}

.progressLine {
    margin: 10px 0;
    background: #585656;
    height: 10px;
    position: relative;
}

.progressLineGreen {
    background: #00A76D;
    height: 10px;
}

.maxAmount {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.5px;
    color: #D5D2CD;
}

.line {
    height: 1px;
    background: #585656;
}

.paragraph {
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 34px;
    letter-spacing: 0.875px;
    color: $WhiteColor;
}

.maxAmount {
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 34px;
    letter-spacing: 0.875px;
    color: $WhiteColor;
}

.amount {
    font-style: normal;
    font-weight: 500;
    font-size: 44px;
    line-height: 54px;
    letter-spacing: 1.1px;
    color: $WhiteColor;
}