@import "../../../Assets/Styles/Imports.scss";

.main {
    display: flex;
    column-gap: 50px;
    row-gap: 50px;
    justify-content: end;
    flex-wrap: wrap;
}

.hidden {
    visibility: hidden;

    @include mobile-size{
        visibility: visible;
        width: 100%;
    }
}
