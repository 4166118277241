@import "../../../Assets/Styles/Imports.scss";

.main {
  max-width: inherit;
  width: 1484px;
}

.content {
  padding: 140px 268px;

  @include mobile-size {
    padding: 25px 0;
    margin-right: -2px;
    padding-bottom: 50px;
  }
}
