@import '../../../Assets/Styles/Imports.scss';

.main {
  display: flex;
  align-items: baseline;
  margin-top: 30px;
  column-gap: 13px;
  max-width: 554px;
}

.icoContainer {
  width: 40px;
}

.icoContainer > img {
}

.errorMessage {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.5px;

  @include dark-mode {
    .errorMessage {
      color: white;
    }
  }

}
