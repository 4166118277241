@import "../../../Assets/Styles/Imports.scss";

.inputSearchContainer {
    position: relative;
    margin-top: 30px;
    margin-bottom: 30px;

    @include mobile-size {
        margin-top: 20px;
        margin-bottom: 0;
    }
}

.flex {
    display: flex;
}

.inputSearch {
    min-width: 220px;
    position: relative;
    padding-top: 20px;
    padding-bottom: 16px;
    padding-left: 37px;
    font-family: 'CeraPro';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.9px;
    color: $PrimaryTextColor;
    border-radius: 35px;
    border: 1px solid #3b3a3a;
    height: 70px;

    @include mobile-size {
        font-size: 18px;
        padding: 8px 19px;
        height: 50px;
    }
}

.inputButton {
    right: 0;
    width: 76px;
    border-bottom-right-radius: 35px;
    border-top-right-radius: 35px;
    position: absolute;
    background: none;
    border: 0;
    z-index: 5;
    background: url('../../../Assets/Images/Search.svg') center no-repeat;
    height: 70px;

    @include mobile-size {
        height: 50px;
        width: 50px;
    }

}

.searchList {
    max-height: 364px;
    width: 370px;
    position: absolute;
    top: 93px;
    left: 23px;
    background: $WhiteColor;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    padding: 27px 35px 23px 15px;
    text-align: left;
    margin-bottom: 0;
    overflow-y: auto;

    @include mobile-size{
        max-width: 300px;
        height: 328px;
        left: 0;
        top: 60px;
        padding: 0;
    }
}

.searchList::-webkit-scrollbar {
    width: 6px;
}

.searchList::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #585656;
}

.searchList::-webkit-scrollbar-thumb {
  background-color: #00A76D;
  border-radius: 3px;
}

.searchListLink {
    font-family: 'CeraPro';
    font-style: normal;
    text-decoration: none;
    display: inline-block;
    width: 100%;
    padding: 6px 15px;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.9px;
    cursor: pointer;

    &:hover {
        background: rgba(0, 167, 109, 0.2);
    }
}


@include dark-mode {
    .inputSearch {
        background: rgba(247,247,247, 0.1);
        color: #D5D2CD;
    }

    .searchList {
      background: linear-gradient(0deg, rgba(15, 15, 15, 0.5), rgba(15, 15, 15, 0.5)), rgba(255, 255, 255, 0.05);
      border: 1px solid #585656;
      box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(13.5914px);
    }

    .searchListLink {
      color: $WhiteColor;

      &:hover {
        background: $ActionColor;
      }
    }
}
