@import "../../../Assets/Styles/Imports.scss";

.panel {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), rgba(15, 15, 15, 0.4);
  border: 1px solid #585656;
  backdrop-filter: blur(13.5914px);
  border-radius: 10px;
  padding: 38px 34px;
}

.info {
  display: flex;
  flex-direction: column;
}

.title {
  font-weight: 400;
  font-size: 19px;
  line-height: 32px;
  letter-spacing: 0.791667px;
}

.value {
  margin-top: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 44px;
  line-height: 54px;
  letter-spacing: 1.1px;
}

.currency {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.5px;
}

.description {
  max-width: 325px;
  margin-top: auto;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: $SecondaryTextColorGray;
}

.chart {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 265px;
  height: 265px;
}