@import "../../../Assets/Styles/Imports.scss";

.popup {
    width: 100%;
    max-width: 765px;
}

.content {


    .popupTitle {
        font-style: normal;
        font-weight: 500;
        font-size: 44px;
        line-height: 54px;
        letter-spacing: 1.1px;
        color: $WhiteColor;
        margin-bottom: 30px;
    }

    .popupParagraph {
        font-style: normal;
        font-weight: 500;
        font-size: 25px;
        line-height: 38px;
        letter-spacing: 1.25px;
        color: $WhiteColor;
    }

    @include mobile-size {
        padding: 0;
    }
}

.content button {
    font-weight: 500;
    font-size: 18px;
    line-height: 34px;
    text-align: center;
    letter-spacing: 0.5px;

    width: 100%;
    max-width: 220px;

    padding: 8px 15px;
    margin-top: 30px;

    @include mobile-size{
        max-width: unset;
        margin-bottom: 20px;
    }
}

.content > h5 {
    font-weight: 700;
    font-size: 20px;
    line-height: 34px;
    letter-spacing: 0.833333px;

    @include mobile-size {
        font-size: 18px;
        line-height: 30px;
        letter-spacing: 0.75px;
        padding-bottom: 27px;
    }
}

.checkbox {
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
    letter-spacing: 0.833333px;

    @include mobile-size {
        font-size: 18px;
        line-height: 30px;
        letter-spacing: 0.75px;
    }
}
