@import '../../../Assets/Styles/Imports.scss';

.header {
  display: flex;
  justify-content: space-between;
  padding: 0 70px 65px 180px;
  min-height: 454px;
  border-bottom: 1px solid #FFFFFF50;

  @include mobile-size{
    padding: 0 17px 40px 17px;
    min-height: 300px;
    display: block;
  }
}

.title {
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 38px;
  letter-spacing: 1.25px;
  color: #948E8D;
  margin: 0;
}

.subtitle {
  font-weight: 400;
  font-size: 25px;
  line-height: 38px;
  letter-spacing: 1.25px;
  margin-bottom: 50px;
}

.buttonsContainer{
  align-self: flex-end;

  @include mobile-size{
    display: flex;
  }
}

.infoContainer {
  align-self: flex-end;
}

.button {
  display: flex;
  align-items: center;
  column-gap: 20px;
  border: 0;
  margin: 20px 0;
  padding: 0;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.5px;

  &:hover {
    color: $ActionColor;
  }

  &:nth-last-child(1) {
    margin-bottom: 0;
  }

  @include mobile-size{

    width: auto;

    :nth-child(2){
      display: none;
    }
  }
}

.mg_bottom_0 {
  margin-bottom: 0;
}

.buttonIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;

  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 2px 17px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(69.8987px);
  border-radius: 30px;

  @include mobile-size{
    margin-right: 20px;
  }
}

.detailsMenu {
  position: absolute;
  z-index: 5;
  background: #000;
  width: 277px;
  top: 0;
  right: 0;
  padding: 25px;

  @include mobile-size {
    position: relative;
    width: 100%;
    padding: 10px;
  }

  img {
    float: right;
  }
}

.detailsMenuLink {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: $WhiteColor;
  padding: 5px 15px;

  &:hover {
    background: $ActionColor;
    border-radius: 2px;
    cursor: pointer;
  }
}

@include dark-mode {
  .button {
    background: transparent;
  }
}

