@import "../../../Assets/Styles/Imports.scss";

.flex {
    display: flex;
    flex-wrap:wrap;
    justify-content: start;

    div {
        flex-basis: 41%;

    }

    @include mobile-size{
        display: block;
    }

}

.flex > div{
    margin: 1%;
}


.number {
    font-style: normal;
    font-weight: 500;
    font-size: 44px;
    line-height: 54px;
    letter-spacing: 1.1px;
    color: $WhiteColor;
    margin-right: 80px;
    flex-basis: 11%!important;
}

.question {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 54px;
    letter-spacing: 1.1px;
    color: $WhiteColor;
}

.salutationWrapper .answerContainer{
    margin-top: 50px;
}

.radioWrapper {
    display: flex;

    @include mobile-size{
        display: unset;
    }
}

.radio{
    margin-right: 40px;

    @include mobile-size{
        display: block;
        margin-bottom: 25px;
    }
}